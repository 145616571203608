/**
 * Utilities/typography stylesheet
 */

.u-text-center,
%u-text-center {
    text-align: center !important;
}

.u-text-left,
%u-text-left {
    text-align: left !important;
}

.u-text-right,
%u-text-right {
    text-align: right !important;
}

.u-text-small,
%u-text-small {
    @include font-size(14);
}

.u-text-medium,
%u-text-medium {
    @include font-size(18);
}

.u-text-large,
%u-text-large {
    @include font-size(20);
}

.u-text-strong,
%-u-text-strong {
    font-weight: $font-bold !important;
}

.u-text-wrap,
%u-text-wrap {
    white-space: normal !important;
}

.u-text-nowrap,
%u-text-nowrap {
    white-space: nowrap !important;
}

.u-text-caps,
%u-text-caps {
    text-transform: uppercase !important;
    letter-spacing: .05em !important;
}

@each $breakpoint in $breakpoints {
    $key: nth($breakpoint, 1);
    $value: nth($breakpoint, 2);

    .u-text-center--#{$value},
    %u-text-center--#{$value} {
        @include respond-min(#{$key}) {
            text-align: center !important;
        }
    }

    .u-text-left--#{$value},
    %u-text-left--#{$value} {
        @include respond-min(#{$key}) {
            text-align: left !important;
        }
    }

    .u-text-right--#{$value},
    %u-text-right--#{$value} {
        @include respond-min(#{$key}) {
            text-align: right !important;
        }
    }

    .u-text-small--#{$value},
    %u-text-small--#{$value} {
        @include respond-min(#{$key}) {
            @include font-size(14);
        }
    }

    .u-text-medium--#{$value},
    %u-text-medium--#{$value} {
        @include respond-min(#{$key}) {
            @include font-size(18);
        }
    }

    .u-text-large--#{$value},
    %u-text-large--#{$value} {
        @include respond-min(#{$key}) {
            @include font-size(20);
        }
    }

    .u-text-strong--#{$value},
    %u-text-strong--#{$value} {
        @include respond-min(#{$key}) {
            font-weight: $font-bold !important;
        }
    }

    .u-text-wrap--#{$value},
    %u-text-wrap--#{$value} {
        @include respond-min(#{$key}) {
            white-space: normal !important;
        }
    }

    .u-text-nowrap--#{$value},
    %u-text-nowrap--#{$value} {
        @include respond-min(#{$key}) {
            white-space: nowrap !important;
        }
    }

    .u-text-caps--#{$value},
    %u-text-caps--#{$value} {
        @include respond-min(#{$key}) {
            text-transform: uppercase !important;
            letter-spacing: .05em !important;
        }
    }
}
