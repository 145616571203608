/**
 * Config/vars stylesheet
 *
 * Table of contents
 * 1) Breakpoints
 * 2) Colours
 * 3) Typography
 * 4) Layout
 * 5) Z-index
 * 6) Utility
 * 7) CDN
 */



/**
 * 1) Breakpoints
 */

$xs: 30em; // 480px --> $bp1
$sm: 48em; // 768px --> $bp2
$md: 62em; // 992px --> $bp3
$lg: 75em; // 1200px --> $bp4

$breakpoints: (
    $xs "xs",
    $sm "sm",
    $md "md",
    $lg "lg",
 );



/**
 * 2) Colors
 */

$color-text: #000000;
$color-bg: #f1f1f1;
$color-link: #61869b;

$color-white: #FFFFFF;
$color-grey: #9E9E9E;
$color-green: #98c679;
$color-black: #000000;

$color-primary: #005c83;
$color-secondary: #ba9d65;

/**
 * 3) Typography
 */

$font-sans: Montserrat,Helvetica,Roboto,Arial,sans-serif;
$font-serif: 'Lusitana', serif;

$font-black: 900;
$font-bold: 700;
$font-medium: 500;
$font-regular: 400;
$font-light: 300;



/**
 * 4) Layout
 */

$baseline: 20px;



/**
 * 5) Z-index
 */

$z-index-1: 1;
$z-index-2: 5;
$z-index-3: 10;
$z-index-4: 15;
$z-index-5: 20;



/**
 * 6) Utility
 */

$spacing-sizes: (
    'none': 0,
    'xxs': .25,
    'xs': .5,
    's': 1,
    'one': 1,
    'm': 1.5,
    'l': 2,
    'two': 2,
    'three': 3,
    'xl': 4,
    'four': 4,
    'five': 5,
    'xxl': 6,
    'jumbo': 8,
);



$spacing-edges: (
    'top',
    'right',
    'bottom',
    'left',
    'vertical' ('top' 'bottom'),
    'horizontal' ('left' 'right'),
);



/**
 * 7) CDN
 */

$cdn: 'https://placehold.it/';
