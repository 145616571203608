/**
 * Modules/gallery stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */

/**
 * 1) Block
 */

.gallery {
  margin-top: $baseline*5;
}

/**
 * 2) Element
 */

.gallery__item {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
}

/**
 * 3) Modifier
 */

.gallery--nav {
  margin-top: $baseline;
}

.gallery__item--nav {
  height: 15vh !important;
  margin: 0 $baseline/2;

  @include respond-between(991px, 1369px) {
    height: 12vh !important;
  }
}
