/**
 * Interface/navigation stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */

/**
 * 1) Block
 */

.js-toggle-left-navigation {
  margin-bottom: 20px;
  float: left;
}

.left__nav {
  position: relative;
  overflow: hidden;
  z-index: 0;
  display: block;
  clear: both;
  overflow: hidden;

  .nav__sub, ul {
    margin-left: 0 !important;
    display: inline-block !important;
    position: relative;
    background: none !important;
    margin-bottom: 0;
    max-width: 100%;
    width: calc(100% - 0px);
    overflow: visible;

    .nav__item, li {
      display: block;

      .nav__sub, ul {
        padding: 20px 10px;

        .nav__item, li {

          &.selected {
            > .nav__link, > a {
              font-weight: $font-black !important;
              border-left: 3px solid $color-primary;
              color: $color-primary;
            }
          }

          &:hover {
            > .nav__link, > a {
              color: $color-primary;
              border-left: 3px solid $color-primary;
            }
          }

          .nav__link, a {
            padding: 6px 0 6px 15px;
            line-height: 15px;
            min-height: 15px;
            display: block;
            width: 100%;
            font-size: 15px;
            color: $color-black;
            text-decoration: none;
            border-left: 3px solid $color-black;
          }
        }
      }

      &.selected {
        > .nav__link, > a {
          font-weight: $font-black !important;
          color: $color-primary;
          border-left: 3px solid $color-primary;
        }
      }

      &:hover {
        > .nav__link, > a {
          color: $color-primary;
          border-left: 3px solid $color-primary;
        }
      }

      &:first-child {
        border-top: 0px;
      }

      .nav__link, a {
        padding: 8px 0 8px 15px;
        line-height: 20px;
        min-height: 20px;
        display: block;
        width: 100%;
        font-size: 16px;
        color: $color-black;
        text-decoration: none;
        border-left: 3px solid $color-black;
      }
    }
  }
}

.shop__nav {
  position: relative;
  transition: opacity 0.3s ease-out;
  opacity: 0;
  height: 0;
  z-index: 0;
  display: block;
  clear: both;
  overflow: hidden;

  @include respond-min($md) {
    opacity: 1 !important;
    height: auto !important;
    overflow: visible !important;
    transition: opacity 0.3s ease-out;
  }

  &.active {
    opacity: 1;
    height: auto;
    overflow: hidden;
    margin-bottom: 20px;
    transition: opacity 0.3s ease-out;

    .nav__sub, ul {
      height: auto;
      overflow: visible;
    }
  }

  .nav__sub, ul {
    margin-left: 0 !important;
    display: inline-block !important;
    position: relative;
    background: none !important;
    margin-bottom: 0;
    max-width: 100%;
    width: calc(100% - 0px);
    overflow: visible;

    .nav__item, li {
      display: block;

      .nav__sub, ul {
        padding: 20px 10px;

        .nav__item, li {

          &.selected {
            > .nav__link, > a {
              color: $color-primary;
              font-weight: $font-black !important;
              border-left: 3px solid $color-primary;
            }
          }

          &:hover {
            > .nav__link, > a {
              border-left: 3px solid $color-primary;
            }
          }

          .nav__link, a {
            padding: 6px 0 6px 15px;
            line-height: 15px;
            min-height: 15px;
            display: block;
            width: 100%;
            font-size: 15px;
            color: $color-black;
            text-decoration: none;
            border-left: 3px solid $color-black;
          }
        }
      }

      &.selected {
        > .nav__link, > a {
          color: $color-primary;
          font-weight: $font-black !important;
          border-left: 3px solid $color-primary;
        }
      }

      &:hover {
        > .nav__link, > a {
          color: $color-primary;
          border-left: 3px solid $color-primary;
        }
      }

      &:first-child {
        border-top: 0px;
      }

      .nav__link, a {
        padding: 8px 0 8px 15px;
        line-height: 20px;
        min-height: 20px;
        display: block;
        width: 100%;
        font-size: 16px;
        color: $color-black;
        text-decoration: none;
        border-left: 3px solid $color-black;
      }
    }
  }

}

.nav {
  display: none;
  width: 100%;
  height: calc(100vh - 75px);
  background-color: $color-white;
  position: fixed;
  top: 75px;
  left: 0;
  z-index: $z-index-4;
  overflow-y: auto;

  @include respond-min($sm) {
    display: block;
    width: 350px;
    height: 100vh;
    transition: transform .3s ease;
    transform: translateX(-350px);
    background: none !important;
  }

  @include respond-min($md) {
    width: 100%;
    height: 50px;
    position: static;
    transition: none;
    transform: none;
    overflow: visible;
    background-color: $color-white;
    padding-top: 10px;
    transition: all .3s ease;

  }
  @include respond-max(991px) {
    background-color: $color-bg !important;
    padding-bottom: 150px;
  }

}

.menu-is-active {
  @include respond-max($md) {
    &:after {
      content: '';
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, .8);
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: $z-index-1;
    }
  }

  .nav {
    display: block;

    @include respond-between($sm, $md) {
      box-shadow: 5px 0 -5px -2px $color-black;
      transform: translateX(0);
    }
  }
}

/**
 * 2) Element
 */

.language {
  @include respond-min($md) {
    float: right;
    min-height: 80px;
    line-height: 80px;
    padding: 0 25px;
    transition: all .3s ease;
  }

  @include respond-between($md, 1199px) {
    padding: 0 20px !important;
  }

  .nav__item {

    &.has-sub {
      .nav__sub {
        background-color: $color-white;
        padding: 30px 20px;
        min-width: 250px;
        left: -26px !important;
        box-shadow: 1px 1px 6px rgba(0, 0, 0, .1);

        .nav__item {
          display: block;

          &.selected, &:hover {
            > .nav__link {
              color: $color-primary;
              font-weight: $font-bold;
              border-left: 3px solid $color-primary;
            }
          }

          &:first-child {
            border-top: 0px;
          }

          .nav__link {
            line-height: 20px;
            min-height: 20px;
            font-size: 14px;
            width: 100%;
            display: block;
            color: $color-black;
            width: 100%;
            border-left: 3px solid $color-black;
            font-weight: $font-regular;
            padding: 8px 0 8px 15px;
          }
        }
      }
    }

    > .nav__link {
      text-decoration: none;
      font-size: 22px;
      color: $color-black;

      @include respond-between($md, 1199px) {
        font-size: 20px !important;
      }
    }

    .nav__sub {
      min-width: 150px !important;

      .nav__item {

        > .nav__link {
          text-decoration: none;
          font-size: 14px;
          color: $color-black;
        }
      }
    }
  }
}

.header {
  .topbar{
    .topbar-cta{
      text-align: center;
      font-size: 15px;
      p{
        margin-bottom: 0;
      }
      a{
        font-weight: bold;
        font-family: $font-serif;
        font-size: 18px;
        color: $color-primary;
        margin-left: 10px;
      }
    }
  }
  .navigation-bar{
    background-color: #ece6db;
    background-image: url(/assets/dist/img/pattern__white.png);
    background-size: 1.8rem;
    background-position: 0 .2666666667rem;
  }
  .nav__primary {

    @include respond-max(991px) {
      padding: 20px;
      background-color: $color-bg;

      .nav__item {
        position: relative;
        border-bottom: 1px solid rgba($color-black, .1);

        &:last-child {
          border: 0;
        }

        &.selected {
          > .nav__link {
            color: $color-primary;
            font-weight: $font-bold;
          }
        }

        .sub-toggle {
          display: none;
          color: $color-black;
        }

        &.has-sub {
          .sub-toggle {
            display: inline-block;

            .fa {
              font-weight: $font-light;
            }
          }

          .nav__sub {
            border-top: 1px solid rgba($color-black, .1);
            padding: 15px;

            .nav__item {
              border: 0;
              line-height: 25px !important;
              min-height: 25px !important;

              .nav__link {
                padding: 3px 10px;
                line-height: 25px !important;
                min-height: 25px !important;
              }
            }
          }

        }

        .nav__link {
          color: $color-black;
          text-decoration: none;
        }
      }

    }

    @include respond-min($md) {
      .nav__item {
        position: relative;

        &.has-sub {
          .sub-toggle {
            display: none;
          }

          .nav__sub {
            background-color: $color-white;
            padding: 30px 20px;
            min-width: 250px;
            left: -20px;
            box-shadow: 1px 1px 2px rgba(0, 0, 0, .1);

            .nav__item {
              display: block;

              &.selected {
                > .nav__link {
                  color: $color-primary;
                  font-weight: $font-bold;
                  border-left: 3px solid $color-primary;
                }
              }

              &:hover {
                > .nav__link {
                  color: $color-primary;
                  border-left: 3px solid $color-primary;
                }
              }

              &:first-child {
                border-top: 0px;
              }

              .nav__link {
                line-height: 20px;
                min-height: 20px;
                font-size: 13px;
                width: 100%;
                display: block;
                color: $color-black;
                width: 100%;
                border-left: 3px solid $color-black;
                font-weight: $font-medium;
                padding: 8px 0 8px 15px;
              }
            }
          }
        }

        &.selected {
          > .nav__link {
            color: $color-primary;
            font-weight: $font-bold;
          }
        }

        &:hover {
          > .nav__link {
            color: $color-primary;
          }
        }

        .nav__link {
          text-decoration: none;
          font-size: 15px;
          line-height: 26px;
          letter-spacing: 1px;
          padding: 10px 20px;
          display: block;
          font-weight: $font-medium;
          color: #0a0a0a;
          text-transform: uppercase;
          transition: all .3s ease;

          @include respond-between($md, 1369px) {
            padding: 5px 10px 5px 15px;
          }

          @include respond-between($md, 1199px) {
            padding: 5px 6px 5px 6px;
          }
        }
      }
    }
  }

  .nav__secondary {
    @include respond-max(991px) {
      padding: 20px;
      background-color: $color-bg;

      .nav__item {
        position: relative;
        border-bottom: 1px solid rgba($color-black, .1);

        &:last-child {
          border: 0;
        }

        &.selected {
          > .nav__link {
            color: $color-primary;
            font-weight: $font-black;
          }
        }

        .sub-toggle {
          display: none;
          color: $color-black;
        }

        &.has-sub {
          .sub-toggle {
            display: inline-block;

            .fa {
              font-weight: $font-light;
            }
          }

          .nav__sub {
            border-top: 1px solid rgba($color-black, .1);
            padding: 15px;

            .nav__item {
              border: 0;
              line-height: 25px !important;
              min-height: 25px !important;

              .nav__link {
                padding: 3px 10px;
                font-size: 16px;
                line-height: 25px !important;
                min-height: 25px !important;
              }
            }
          }

        }

        .nav__link {
          color: $color-black;
          font-size: 16px;
          text-decoration: none;
        }
      }
    }
  }
}

.nav__sub {
  display: none;

  .submenu-is-active > & {
    display: block;
  }

  @include respond-min($md) {
    width: 100%;
    background-color: $color-white;
    text-align: left;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: $z-index-5;

    .submenu-is-active & {
      display: none;
    }
  }
}

.nav__item {
  &.has-sub {
    position: relative;

    @include respond-min($md) {
      &:hover .nav__sub {
        display: block;
      }
    }
  }

  @include respond-min($md) {
    display: inline-block;

    &:first-child > .nav__link {
      padding-left: 0;
    }
    &:last-child > .nav__link {
      padding-right: 0;
    }

    &:last-child .nav__sub {
      right: 0;
      left: auto !important;
    }
  }
}

.nav__link {
  display: block;
  padding: 0 .5em;
  min-height: 30px;
  line-height: 30px;

  @include respond-min($md) {
    display: inline-block;
  }
}

/**
 * 3) Modifier
 */

.nav__link--sub {
  padding: 0 1.5em;

  @include respond-min($md) {
    padding: 0 0.5em;
  }
}

