/**
 * Config/bower stylesheet
 *
 * Table of contents
 * 1) Normalize
 * 2) Bootstrap Grid
 */



/**
 * 1) Normalize
 */

@import "../../../node_modules/normalize.css/normalize";

/**
 * 2) Bootstrap Grid
 */

@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/clearfix";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/grid-framework";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/grid";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/grid";

.container-fluid {
  max-width: 1400px;
  width: 100%;

  .checkout &{
    max-width: 100%;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }

  .row {
    margin-left: -10px;
    margin-right: -10px;
  }
  .col-full,
  .col-xs-12,
  .col-xs-11,
  .col-xs-10,
  .col-xs-9,
  .col-xs-8,
  .col-xs-7,
  .col-xs-6,
  .col-xs-5,
  .col-xs-4,
  .col-xs-3,
  .col-xs-2,
  .col-xs-1,
  .col-sm-12,
  .col-sm-11,
  .col-sm-10,
  .col-sm-9,
  .col-sm-8,
  .col-sm-7,
  .col-sm-6,
  .col-sm-5,
  .col-sm-4,
  .col-sm-3,
  .col-sm-2,
  .col-sm-1 {
    padding-left: 10px;
    padding-right: 10px;
  }

  @include respond-min($sm) {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.container {
     @include respond-max($sm) {
         padding: 0 10px !important;

         .row {
             margin-left: -5px;
             margin-right: -5px;
         }

         .col-full,
         .col-xs-12,
         .col-xs-11,
         .col-xs-10,
         .col-xs-9,
         .col-xs-8,
         .col-xs-7,
         .col-xs-6,
         .col-xs-5,
         .col-xs-4,
         .col-xs-3,
         .col-xs-2,
         .col-xs-1,
         .col-sm-12,
         .col-sm-11,
         .col-sm-10,
         .col-sm-9,
         .col-sm-8,
         .col-sm-7,
         .col-sm-6,
         .col-sm-5,
         .col-sm-4,
         .col-sm-3,
         .col-sm-2,
         .col-sm-1 {
             padding-left: 5px;
             padding-right: 5px;
         }
     }
 }

.container-big {
  @include respond-min(1370px) {
    width: 1350px !important;

    .row {
      margin-left: -10px;
      margin-right: -10px;
    }
    .col-full,
    .col-xs-12,
    .col-xs-11,
    .col-xs-10,
    .col-xs-9,
    .col-xs-8,
    .col-xs-7,
    .col-xs-6,
    .col-xs-5,
    .col-xs-4,
    .col-xs-3,
    .col-xs-2,
    .col-xs-1,
    .col-sm-12,
    .col-sm-11,
    .col-sm-10,
    .col-sm-9,
    .col-sm-8,
    .col-sm-7,
    .col-sm-6,
    .col-sm-5,
    .col-sm-4,
    .col-sm-3,
    .col-sm-2,
    .col-sm-1 {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  @include respond-between(1200px, 1369px) {
    width: 1250px !important;
    .row {
      margin-left: -10px;
      margin-right: -10px;
    }
    .col-full,
    .col-xs-12,
    .col-xs-11,
    .col-xs-10,
    .col-xs-9,
    .col-xs-8,
    .col-xs-7,
    .col-xs-6,
    .col-xs-5,
    .col-xs-4,
    .col-xs-3,
    .col-xs-2,
    .col-xs-1,
    .col-sm-12,
    .col-sm-11,
    .col-sm-10,
    .col-sm-9,
    .col-sm-8,
    .col-sm-7,
    .col-sm-6,
    .col-sm-5,
    .col-sm-4,
    .col-sm-3,
    .col-sm-2,
    .col-sm-1 {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  @include respond-between(1200px, 1269px) {
    width: 1170px !important;
  }

  @include respond-max(991px) {
    width: 100% !important;
  }

  .row {
    margin-left: -10px;
    margin-right: -10px;
  }
  .col-full,
  .col-xs-12,
  .col-xs-11,
  .col-xs-10,
  .col-xs-9,
  .col-xs-8,
  .col-xs-7,
  .col-xs-6,
  .col-xs-5,
  .col-xs-4,
  .col-xs-3,
  .col-xs-2,
  .col-xs-1,
  .col-sm-12,
  .col-sm-11,
  .col-sm-10,
  .col-sm-9,
  .col-sm-8,
  .col-sm-7,
  .col-sm-6,
  .col-sm-5,
  .col-sm-4,
  .col-sm-3,
  .col-sm-2,
  .col-sm-1 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.container--full {
        position: relative;

        @include respond-max($sm) {
                width: 100%;
                padding: 0;
        }
}



.col-full {
        padding-left: 15px;
        padding-right: 15px;

        @include respond-max($xs) {
                width: 100% !important;
        }
}
