/**
* Modules/news stylesheet
*
* Table of contents
* 1) Block
* 2) Element
* 3) Modifier
*/

/**
* 1) Block
*/

.news__pages {
  display: block;
  width: 100%;
  position: relative;

  .news-pages {
    color: transparent;
    display: block;
    width: 100%;
    position: relative;
    text-align: right;
    clear: both;

    &.paginering-top {
      .item {
        margin-bottom: 30px;
      }
    }

    &.bottom {
      .item {
        margin-top: 0px;
      }
    }

    .next, .previous {
      display: none;
    }

    .item {
      background: $color-bg;
      width: 30px;
      height: 30px;
      line-height: 30px;
      display: inline-block;
      text-align: center;
      border-radius: 100%;
      margin: 0 2px;
      font-size: 14px;

      .button {
        clear: both;
      }

      a.page {
        color: $color-text;
        display: block;
      }

      &.dots {
        background: none !important;
        color: $color-text;
      }

      @include respond-min($md) {
        &:hover {
          background-color: $color-text;

          a {
            color: $color-white;
          }
        }
      }

      a {
        text-decoration: none !important;
        color: $color-primary;

        @include respond-min($md) {
          &:hover {
            color: $color-white;
          }
        }
      }

      &.selected {
        background-color: $color-text;
        color: $color-white;
      }
    }
  }

  .next, .previous {
    display: none;
  }
}

.news {

  a.more {
    color: $color-text;
    text-decoration: none;
    font-weight: $font-bold;
    transition: color 0.25s;
    font-size: 16px;
    position: relative;
    display: inline-block;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 1px;
      width: 100%;
      transition: width 0s ease;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      height: 1px;
      width: 100%;
      background: $color-text;
      transition: width .5s ease;
    }

    @include respond-min($md) {
      &:hover {
        color: $color-text;

        &:before {
          width: 0%;
          background: $color-text;
          transition: width .5s ease;
        }

        &:after {
          width: 0%;
          background: transparent;
          transition: width 0s ease;
        }
      }
    }

  }

  @include respond-min($md) {
    a.more {
      &:hover {
        color: $color-text;

        &:before {
          width: 0%;
          background: $color-text;
          transition: width .5s ease;
        }

        &:after {
          width: 0%;
          background: transparent;
          transition: width 0s ease;
        }
      }
    }
  }

  .item {

    @include respond-min($md) {
      .inner {
        &:hover {
          .image {
            -webkit-transform: scale(1.05);
            -ms-transform: scale(1.05);
            transform: scale(1.05);
            position: relative;
            transition: all .3s ease;
          }
        }
      }
    }

    .image__col {
      overflow: hidden;
      position: relative;

      .image {
        transition: all .3s ease;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        display: block;
        padding-bottom: 75%;
      }
    }

    p {
      font-size: 16px;
      margin-bottom: 3px;
    }

    .date {
      font-size: 13px;
      color: $color-secondary;
      margin-bottom: 5px;
      display: block;
      font-weight: $font-bold;
    }

    h3 {
      color: $color-text;
      font-weight: $font-bold;

      a {
        color: $color-text;
        font-weight: $font-black;
        text-decoration: none;

        @include respond-min($md) {
          &:hover {
            color: lighten($color-text, 0%);
          }
        }

      }
    }

    a.more {
      color: $color-text;
      text-decoration: none;
      font-weight: $font-bold;
      transition: color 0.25s;
      position: relative;
      display: inline-block;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 1px;
        width: 100%;
        transition: width 0s ease;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        height: 1px;
        width: 100%;
        background: $color-text;
        transition: width .5s ease;
      }

      @include respond-min($md) {
        &:hover {
          color: $color-text;

          &:before {
            width: 0%;
            background: $color-text;
            transition: width .5s ease;
          }

          &:after {
            width: 0%;
            background: transparent;
            transition: width 0s ease;
          }
        }
      }
    }
  }
}

.headlines {
  background-color: $color-bg;
}


/**
* 2) Element
*/

/**
* 3) Modifier
*/
