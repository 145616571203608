/**
 * Utilities/padding stylesheet
 */

@each $size, $scale in $spacing-sizes {
    .u-padding,
    %u-padding {
        padding: $baseline !important;
    }

    @each $breakpoint in $breakpoints {
        $key: nth($breakpoint, 1);
        $value: nth($breakpoint, 2);

        .u-padding--#{$value},
        %u-padding--#{$value} {
            @include respond-min(#{$key}) {
                padding: $baseline !important;
            }
        }
    }

    @each $edge, $sub in $spacing-edges {
        .u-padding-#{$edge},
        %u-padding-#{$edge} {
            @if $sub {
                @each $subedge in $sub {
                    padding-#{$subedge}: $baseline !important;
                }
            } @else {
                padding-#{$edge}: $baseline !important;
            }
        }

        @each $breakpoint in $breakpoints {
            $key: nth($breakpoint, 1);
            $value: nth($breakpoint, 2);

            .u-padding-#{$edge}--#{$value},
            %u-padding-#{$edge}--#{$value} {
                @include respond-min(#{$key}) {
                    @if $sub {
                        @each $subedge in $sub {
                            padding-#{$subedge}: $baseline !important;
                        }
                    } @else {
                        padding-#{$edge}: $baseline !important;
                    }
                }
            }
        }
    }
}



@each $size, $scale in $spacing-sizes {
    .u-padding-#{$size},
    %u-padding-#{$size} {
        padding: ($baseline * $scale) !important;
    }

    @each $breakpoint in $breakpoints {
        $key: nth($breakpoint, 1);
        $value: nth($breakpoint, 2);

        .u-padding-#{$size}--#{$value},
        %u-padding-#{$size}--#{$value} {
            @include respond-min(#{$key}) {
                padding: ($baseline * $scale) !important;
            }
        }
    }

    @each $edge, $sub in $spacing-edges {
        .u-padding-#{$edge}-#{$size},
        %u-padding-#{$edge}-#{$size} {
            @if $sub {
                @each $subedge in $sub {
                    padding-#{$subedge}: ($baseline * $scale) !important;
                }
            } @else {
                padding-#{$edge}: ($baseline * $scale) !important;
            }
        }

        @each $breakpoint in $breakpoints {
            $key: nth($breakpoint, 1);
            $value: nth($breakpoint, 2);

            .u-padding-#{$edge}-#{$size}--#{$value},
            %u-padding-#{$edge}-#{$size}--#{$value} {
                @include respond-min(#{$key}) {
                    @if $sub {
                        @each $subedge in $sub {
                            padding-#{$subedge}: ($baseline * $scale) !important;
                        }
                    } @else {
                        padding-#{$edge}: ($baseline * $scale) !important;
                    }
                }
            }
        }
    }
}
