/**
 * Modules/breadcrumbs stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */

/**
 * 1) Block
 */

.breadcrumbs__bar {
  position: relative;

  p {
    display: none;
  }

  .seperator {
    display: none !important;
  }

  .breadcrumbs {
    font-size: 14px !important;
    color: lighten($color-text, 35%);
    align-self: flex-end;
    padding: 15px 0;
    text-transform: lowercase;
    border-top: 1px solid $color-white;

    a {
      display: inline-block;
      font-size: 14px !important;
      color:  $color-primary !important;
      text-decoration: none !important;

      &:after {
        content: "\f101";
        font-size: 12px;
        font-weight: $font-light;
        color: $color-primary;
        font-family: "Font Awesome 5 Pro";
        position: relative;
        top: 1px;
        margin-left: 10px;
        margin-right: 5px;
      }

      @include respond-min($md) {
        &:hover {
          color: $color-secondary !important;
        }
      }

      &:last-child {
        text-decoration: none;
        cursor: default !important;
        color: lighten($color-text, 35%) !important;

        @include respond-min($md) {
          &:hover {
            color: lighten($color-text, 35%) !important;
          }
        }

        &:after {
          display: none !important;
        }
      }
    }
  }
}

/**
 * 2) Element
 */

/**
 * 3) Modifier
 */
