/**
 * Utilities/clearfix stylesheet
 */

@mixin clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

.cf,
.clearfix,
%clearfix {
    @include clearfix;
}
