/**
 * Modules/buttons stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */

/**
 * 1) Block
 */

.btn {
  display: inline-block;
  padding: $baseline/4 $baseline/2;
  background: none;
  border: none;
  text-decoration: none;
  outline: none;
  position: relative;
}

.button, #bBasket .button.back, html input[type=button], input[type=reset], input[type=submit], .content__col button:not(.cart__button, .button__cart__selected, .button__cart__grey) {
  background-color: $color-secondary;
  text-decoration: none;
  text-align: center;
  border: 0;
  color: $color-white;
  display: inline-block;
  line-height: 35px;
  min-height: 35px;
  padding: 0 12px 0 12px;
  font-size: 14px;
  font-weight: $font-regular;
  font-family: $font-serif;
  transition: all .3s ease;
  cursor: pointer;
  border-radius: 5px;

  .fa, .fab, .fal {
    transition: all .3s ease;
    position: relative;
    margin-left: 5px;
    left: 0;
    font-size: 14px;
    font-weight: $font-light;
  }
  .far{
    font-weight: $font-regular;
  }
  .fas{
    font-weight: $font-bold;
  }

  @include respond-min($md) {
    &:hover {
      transition: all .3s ease;
      background-color: $color-primary;
      color: $color-white;

      .fa, .fab, .fal, .far, .fas {
        transition: all .3s ease;
        position: relative;
        left: 10px;
      }
    }
  }

  &.lines {
    background-color: transparent !important;
    border: 1px solid $color-black;
    color: $color-black !important;
    transition: all .3s ease;
    line-height: 43px !important;
    min-height: 43px !important;

    @include respond-min($md) {
      &:hover {
        transition: all .3s ease;
        background-color: transparent !important;
        border: 1px solid $color-secondary;
        color: $color-secondary !important;
      }
    }
  }

  &.reverse {
    background-color: $color-primary !important;
    transition: all .3s ease;
    color: $color-white !important;

    @include respond-min($md) {
      &:hover {
        transition: all .3s ease;
        background-color: $color-secondary !important;
      }
    }
  }
}

.menu-toggle {
  position: absolute;
  width: 60px;
  height: 50px;
  right: 23px;
  top: 8px;
  color: $color-black;
  display: block;
  transition: all .2s ease;

  @include respond-min($md) {
    top: 41px;
    right: 50px;
    cursor: pointer;
    display: none;

    &:hover {
      opacity: 0.7;
    }
  }

  .hamburger-box {
    z-index: -5;
    position: relative;
  }

  &:after {
    content: "menu";
    transition: all .2s ease;
    position: absolute;
    color: $color-black;
    font-weight: $font-light;
    font-weight: normal !important;
    font-size: 14px;
    text-transform: uppercase;
    right: -26px;
    bottom: 13px;
    -webkit-transform: rotate(20deg);
    transform: rotate(-90deg);
    opacity: 1;
  }

  &.is-active {
    right: 15px;
    transition: all .2s ease;

    &:after {
      opacity: 0;
      transition: all .2s ease;
    }

    .hamburger-inner {
      &:before {
        top: -10px !important;
      }

      &:after {
        bottom: -10px !important;
      }
    }
  }

  .hamburger-inner {
    height: 1px !important;
    background-color: $color-black;

    &:before {
      background-color: $color-black;
      top: -16px !important;
      height: 1px !important;
    }

    &:after {
      background-color: $color-black;
      bottom: -16px !important;
      height: 1px !important;
    }
  }
}

.sub-toggle {
  width: 20%;
  max-height: 30px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  .fa-angle-up {
    display: none;
  }

  &.is-active .fa-angle-up {
    display: block;
  }

  &.is-active .fa-angle-down {
    display: none;
  }

  @include respond-min($md) {
    display: none;
  }
}

/**
 * 2) Element
 */

/**
 * 3) Modifier
 */

.btn--block {
  display: block;
}
