/**
 * Modules/gallery stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */

/**
 * 1) Block
 */


.photos {
  margin-top: 30px;

  .photo {
    padding-bottom: 75%;
    display: block;
    background-size: cover;
    background-position: center;
    box-shadow: 5px 5px 39px rgba($color-black, .1);
  }

}

/**
 * 2) Element
 */

/**
 * 3) Modifier
 */
