/**
 * Layout/hero stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */

/**
 * 1) Block
 */

.slider--subpage{
  height: 40vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.slider {
  overflow: hidden;
  display: block;
  width: 100%;

  .slick-track {
    display: block;
    width: 100%;
  }

  .slick-list {
    overflow: visible !important;
  }

  .hero {
    .hero__item {

      .slide__image {
        height: 50vh !important;
        background-position: center;
        background-size: cover;
        display: block;

        @include respond-between($sm, 1199px) {
          height: 40vh !important;
        }

        @include respond-max(991px) {
          height: auto !important;
          background-image: none !important;
        }

      }

      .slide__text {
        @include respond-min($sm) {
          position: absolute;
          left: 0;
          right: 0;
          top: 25%;
        }

        @include respond-between($sm, 1199px) {
          top: 15%;
        }

        @include respond-max(767px) {
          position: relative;
          padding: 50px 20px;
          background-color: $color-bg;
        }

        .info {

          &.right {
            @include respond-min($sm) {
              margin-right: 10px;
              float: right;
            }

            .button {
              margin-right: -10px;
            }
          }

          p {
            font-size: 20px;
            color: $color-white;

            @include respond-between($sm, 1199px) {
              font-size: 15px !important;
              line-height: 20px !important;
            }
          }

          h2 {
            font-weight: $font-bold;
            color: $color-white;
            font-size: 32px;
            margin-bottom: 15px;

            @include respond-max(480px) {
              font-size: 24px !important;
            }

            @include respond-between($xs, 767px) {
              font-size: 26px !important;
            }

            @include respond-between($sm, 1199px) {
              font-size: 26px !important;
            }
          }

          h3 {
            font-weight: $font-regular;
            text-transform: uppercase;
            color: $color-secondary;
            font-size: 16px;
            margin-bottom: 5px;

            @include respond-between($sm, 1199px) {
              font-size: 14px !important;
            }
          }

        }
      }


    }
  }


  .slick-dots {
    position: absolute;
    bottom: 25px;
    right: 100px;

    @include respond-max($sm) {
      top: 20px;
      right: 20px !important;
    }

    li {
      display: inline-block;

      button {
        border: 1px solid $color-white;
        background-color: transparent;
        border-radius: 100%;
        display: inline-block;
        height: 10px;
        min-height: 10px;
        margin-left: 8px;
        width: 10px;
        cursor: pointer;
        color: transparent;
        padding: 0;
        text-indent: 30000;
        outline: none;
      }

      &.slick-active, &.hover {
        button {
          background-color: $color-white;
        }
      }

    }

  }
}


/**
 * 2) Element
 */


/**
 * 3) Modifier
 */
