/**
 * Utilities/float stylesheet
 */

.u-pull-left,
%u-pull-left {
  float: left !important;
}

.u-pull-right,
%u-pull-right {
  float: right !important;
}

@each $breakpoint in $breakpoints {
    $key: nth($breakpoint, 1);
    $value: nth($breakpoint, 2);

    .u-pull-left--#{$value},
    %u-pull-left--#{$value} {
        @include respond-min(#{$key}) {
            float: left !important;
        }
    }

    .u-pull-right--#{$value},
    %u-pull-right--#{$value} {
        @include respond-min(#{$key}) {
            float: right !important;
        }
    }
}
