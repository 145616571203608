/**
 * Webshop/filters stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */
/**
 * 1) Block
 */
.info.message, .bMessage {
  background: #d5eed4;
  border-radius: 0;
  text-align: center;
  padding: 10px 20px;
  color: #48b543;
  margin-bottom: 20px;
  margin-top: 20px;
}

/**
 * 2) Element
 */

#bBasket .cms-shop-articles-inner{
  margin-left: -10px;
  margin-right: -10px;
}


.products, #bBasket .cms-shop-articles-inner {
  .product__item {
    text-decoration: none;

    .info {

      .table {
        font-size: 14px;
        line-height: 18px;
        color: $color-black;
      }

      h2 {
        font-size: 18px;
        color: $color-black;
        font-weight: $font-bold;
        margin-bottom: 3px;
      }

      p {
        font-size: 16px;
        color: $color-black;
        line-height: 20px;
        margin-bottom: 5px;
      }

      .stock {
        float: left;
        width: 80%;
        color: $color-secondary;

        &.nostock {
          color: lighten($color-grey, 20%);
        }

        .fa {
          &:before {
            padding: 0;
            margin: 0 5px 0 0;
          }
        }
      }

      .prices {
        .price {
          font-weight: $font-regular;
          color: $color-black;
          font-size: 15px;
          float: left;

          span.vat {
            text-transform: lowercase !important;
          }
        }

        .oldprice {
          position: relative;
          margin-right: 10px;
          font-weight: $font-regular;
          font-size: 15px;
          color: $color-grey;
          text-decoration: line-through;
          float: left;
        }
      }

    }

    @include respond-min($md) {
      &:hover {
        .image {
          -webkit-transform: scale(1.05);
          -ms-transform: scale(1.05);
          transform: scale(1.05);
          position: relative;
          transition: all .3s ease;
        }

        .button {
          .fa {
            left: 10px;
          }

          background: darken($color-secondary, 10%) !important;
          border-color: darken($color-secondary, 10%) !important;
          color: $color-white !important;
        }
      }
    }

    .zoom {
      overflow: hidden;
      padding: 0px;
      border: 1px solid $color-bg;
      position: relative;

      .favorite {
        .fa {
          font-weight: $font-regular;
          color: $color-green;
          position: absolute;
          top: 5px;
          left: 5px;
          z-index: 4;
          font-size: 22px;

          &.active {
            color: $color-green;
            font-weight: 600;
          }

          @include respond-min($md) {
            &:hover {
              color: $color-primary;
            }
          }
        }
      }

      .label {
        position: absolute;
        top: 30px;
        right: 10px;
        font-size: 12px;
        padding: 6px 10px;
        background-color: $color-primary;
        border-radius: 4px 0 0 4px;
        color: $color-white;
        z-index: 15;
      }
    }

    .image {
      transition: all .3s ease;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      display: block;
      padding-bottom: 75%;
      position: relative;
    }
  }

}


/**
 * 3) Modifier
 */
