/**
 * Modules/icons stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */


/**
 * 1) Block
 */

.payments {
  background-color: $color-white;
  border-top: 1px solid $color-bg;

  .flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;
    float: none !important;
    text-align: center;
    position: relative;
  }

  img {
    max-height: 30px;
  }
}


/**
 * 2) Element
 */


/**
 * 3) Modifier
 */
