/**
 * baseline/lists stylesheet
 *
 * Table of contents
 * 1) Lists
 */



/**
 * 1) Lists
 */

ul, ol {
    margin-left: 0;
    margin-bottom: 0;
    padding-left: 0;

    list-style: none;

    ul, ol {
        margin-top: 0;
        margin-bottom: 0;

        list-style: none;
    }
}

dt {
    font-weight: bold;
}

dd {
    margin-left: 0;
    margin-bottom: $baseline/2;
    font-style: italic;
}
