/**
 * Webshop/buttons stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */

/**
 * 1) Block
 */

.product {

  .anchors {
    ul {
      margin-left: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: -moz-flex;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
      justify-content: space-around;
      -webkit-align-content: space-around;
      -ms-flex-line-pack: distribute;
      align-content: space-around;

      @include respond-max(480px) {
        display: block !important;
      }

      .anchor__item {
        list-style: none;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        -webkit-flex-shrink: 1;
        -ms-flex-negative: 1;
        flex-shrink: 1;
        -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        line-height: 45px;
        min-height: 45px;

        border-top: 1px solid $color-bg;
        border-bottom: 1px solid $color-bg;
        text-align: center;

        &:before {
          display: none;
        }

        @include respond-max(480px) {
          display: block !important;
        }

        &.selected {
          background-color: $color-primary;
          border-color: $color-primary;

          .anchor__link {
            color: $color-white !important;
          }

        }

        .anchor__link {
          font-size: 14px;
          display: block;
          line-height: 45px;
          min-height: 45px;
          text-decoration: none;
          color: $color-black;
          font-weight: $font-regular;
        }
      }
    }
  }

  .anchor__tab {
    position: relative;

    table:not(.auto) {
      width: 100%;
      border: 0px !important;
      border-collapse: collapse !important;
      border-spacing: 0px !important;

      tbody {
        vertical-align: top;
      }

      th, td {
        text-align: left;
        border: 0px !important;
        border-collapse: collapse !important;
        border-spacing: 0px !important;
        padding: 4px 10px;

        @include respond-max(480px) {
          display: inline-block !important;
        }
      }

      tr {
        color: $color-text;
        border-bottom: 1px solid $color-bg;

        &:last-child {
          border: 0px;
        }

        td {
          &:nth-child(2n+1) {
            font-weight: $font-bold;
          }
        }
      }
    }

    h2 {
      color: $color-black;
      font-size: 26px;
    }

    p {
      color: $color-text;
    }

    strong {
      font-weight: $font-bold;
    }
  }

  .preview {

    .u-margin-top.cf {
      display: block;
      color: $color-secondary;
      line-height: 40px;
      border: 1px solid $color-secondary;
      width: 250px;
      text-align: center;
      text-decoration: none;
      margin-top: 60px !important;

      @include respond-min($md) {
        &:hover {
          text-decoration: none;
          color: $color-primary;
          border-color: $color-primary;
        }
      }
    }

    @include respond-max(768px) {
      margin-bottom: 30px;
    }

    .gallery {
      margin-top: 0px;
    }

    .slick-dots {
      margin-left: 0;
      text-align: center;

      @include respond-max(480px) {
        margin-left: 20px;
      }

      li {
        position: relative;
        list-style: circle;
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;

        &:before {
          display: none !important;
        }

        &.slick-active {
          button {
            &:before {
              content: "\f111";
              font-family: "Font Awesome 5 Pro";
              color: lighten($color-black, 65%);
              font-weight: $font-bold;
            }
          }
        }

        button {
          font-size: 0;
          line-height: 0;
          display: block;
          padding: 0;
          min-height: inherit !important;
          line-height: inherit !important;
          cursor: pointer;
          color: $color-black;
          border: 0;
          outline: none;
          background: transparent;

          &:before {
            content: "\f111";
            font-family: "Font Awesome 5 Pro";
            color: lighten($color-black, 75%);
            font-size: 7px;
            font-weight: $font-light;
          }
        }
      }
    }

    .js-gallery {
      position: relative;

      @include respond-min($md) {
        float: right;
        width: calc(100% - 170px);
      }

      @include respond-between(991px, 1369px) {
        width: calc(100% - 130px);
      }

      .label {
        position: absolute;
        top: 30px;
        right: 0px;
        font-size: 12px;
        padding: 6px 10px;
        background-color: $color-primary;
        border-radius: 4px 0 0 4px;
        color: $color-white;
        z-index: 15;
      }

      .inner {
        img {
          cursor: zoom-in;
        }
      }
    }

    .box {
      position: relative;
      background-color: #fafafa;
      overflow: hidden;

      img.zoom {
        min-width: 100%;
        height: auto !important;

        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
      }
    }

    .gal {
      .thumb {
        float: left;
        width: 25%;
        position: relative;

        a {
          background-repeat: no-repeat;
          background-size: contain;
          padding-bottom: 100%;
          display: block;
          background-position: center;
          width: 100%;
          text-decoration: none;
        }
      }
    }

    .gallery--nav_wrapper {
      position: relative;
      overflow: hidden;
      padding: 40px 0;
      width: 150px;
      float: left;

      @include respond-between(991px, 1369px) {
        width: 120px;
      }

      &.no-arrows {
        padding: 0 !important;
        margin-left: -5px;
      }

      .prevArrow {
        position: absolute;
        z-index: 3;
        top: 0;
        left: calc(50% - 8px);
        font-size: 28px;
        color: $color-black;
        background-color: transparent;
        text-decoration: none;

        .fa {
          font-weight: $font-light;
        }
      }

      .nextArrow {
        position: absolute;
        z-index: 3;
        bottom: 0;
        right: calc(50% - 8px);
        font-size: 28px;
        color: $color-black;
        background-color: transparent;
        text-decoration: none;

        .fa {
          font-weight: $font-light;
        }
      }

      .gallery--nav {

        .video {
          background-size: 120% !important;
          position: relative;
          border-radius: 10px;

          @include respond-min($md) {
            &:hover {
              &:before {
                transition: all .3s ease;
                font-size: 35px;
              }
            }
          }

          &:before {
            content: "\f144";
            font-family: "Font Awesome 5 Pro";
            font-weight: $font-regular;
            position: absolute;
            top: 50%;
            left: 50%;
            transition: all .3s ease;
            color: $color-white;
            font-size: 25px;
            z-index: 2;
            transform: translateX(-50%) translateY(-50%);
          }

          &:after {
            content: "";
            border-radius: 10px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            z-index: 0;
            bottom: 0;
            background-color: rgba($color-black, .3);
          }
        }

        .gallery__item--nav {
          background-size: contain;
          cursor: pointer;
          margin: 5px 0;
          background-repeat: no-repeat;
          background-position: center !important;
          height: 0;
          border: solid 1px $color-bg;
        }
      }

    }
  }

  .article__scroll {
    background-color: $color-bg;
    padding: 25px;
    overflow: hidden;
    transition: all .3s ease;
    position: fixed;
    top: 272px;
    width: 100%;

    .favorite {
      .fa {
        font-weight: $font-regular;
        color: $color-green;
        position: absolute;
        top: 5px;
        right: -5px;
        z-index: 4;
        font-size: 22px;

        &.active {
          color: $color-green;
          font-weight: 600;
        }

        @include respond-min($md) {
          &:hover {
            color: $color-primary;
          }
        }
      }
    }

    .variation {
      line-height: 45px;

      select {
        background-color: $color-white;
      }
    }

    @include respond-between($lg, 1269px) {
      padding: 22px !important;
    }

    @include respond-between($sm, 991px) {
      padding: 20px;
      top: 217px !important;
    }

    @include respond-max(768px) {
      padding: 15px !important;
      margin-bottom: 30px;
    }

    @include respond-min(1370px) {
      max-width: 419px;
    }

    @include respond-between(1199px, 1369px) {
      max-width: 370px;
    }

    @include respond-between(991px, $lg) {
      max-width: 300px;
    }

    @include respond-between(768px, $md) {
      max-width: 292px;
    }

    @include respond-max(767px) {
      max-width: 370px;
    }

    @include respond-max(767px) {
      top: 0px !important;
      margin: 0 auto !important;
      position: relative !important;
    }

    &.sticky {
      top: 95px;
      transition: all .3s ease;
    }

    &.absolute {
      top: auto !important;
      position: absolute;
      bottom: 20px;
    }

    .inner {
      position: relative;
      z-index: 1;

      .table {
        line-height: 12px;
        font-size: 14px;

        p {
          margin-bottom: 0;
        }
      }

      h1 {
        font-size: 24px;
        font-weight: $font-bold;
        color: $color-black;
        margin-bottom: 20px;
        width: 90%;

        @include respond-between($md, 1199px) {
          font-size: 24px;
        }

        @include respond-between($sm, 991px) {
          font-size: 22px;
        }
      }

      hr {
        margin-bottom: 10px;
        border-color: $color-bg;
      }

      .stock {
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 16px;

        .nostock {
          color: $color-grey;

          .fas, .fa {
            font-weight: $font-light;
            margin-right: 5px;
          }
        }

        .instock {
          color: $color-green;

          .fa, .fas {
            font-weight: $font-light;
            margin-right: 5px;
          }
        }
      }

      .prices {
        margin-bottom: 20px;

        .oldprice {
          color: $color-grey;
          font-weight: $font-bold;
          font-size: 20px;
          text-decoration: line-through;
        }

        .price {
          font-size: 20px;
          color: $color-black;
          font-weight: $font-bold;
        }

        .vat {
          font-size: 14px;
          color: $color-black;
          font-weight: $font-regular;
          margin-bottom: 5px;
          position: relative;
          top: -1px;
        }
      }

      .order {

        .addtocart {
          padding: 0 15px !important;
          height: 45px !important;
          line-height: 45px !important;
          float: right;
          width: calc(100% - 102px) !important;
          margin-top: 0 !important;

          .ui-icon {
            display: none;
          }

          &:after {
            content: '\f061';
            transition: all .3s ease;
            margin-left: 10px;
            font-weight: $font-light;
            font-size: 14px;
            font-family: "Font Awesome 5 Pro";
          }

          @include respond-min($md) {
            &:hover {
              background-color: $color-text;

              &:after {
                transition: all .3s ease;
                margin-left: 15px;
              }
            }
          }
        }

        .amount {

          .qtyminus, .qtyplus {
            max-width: 40px;
            top: 1px;
            position: relative;
            line-height: 42px;
            min-width: 24px;
            min-height: 42px;
            padding: 0 5px;
            float: left;
            border: 1px solid $color-secondary;
            color: $color-white;
            background-color: $color-secondary;
          }

          input {
            max-width: 45px;
            margin-top: 1px;
            padding: 0 10px;
            height: 44px;
            float: left;
            font-size: 13px !important;
            background-color: $color-white;
            text-align: center;
          }
        }
      }

    }
  }

}

/**
 * 2) Element
 */

/**
 * 3) Modifier
 */
