/**
 * Modules/breadcrumbs stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */

.faq {

  h2 {
    font-size: 16px;
    margin-bottom: 40px;
  }

  .toggle-box {

    &.active {
      .toggle {
        color: $color-primary !important;

        &:after {
          content: "\f106";
          float: right;
          color: $color-primary;
          font-family: "Font Awesome 5 Pro";
          font-size: 24px;
        }
      }
    }

    .toggle {
      cursor: pointer;
      font-weight: $font-bold;
      color: $color-black !important;
      width: 100%;
      margin-bottom: 0 !important;
      padding: 10px 40px 10px 0px;
      font-size: 18px !important;
      transition: all .3s ease;
      border-bottom: $color-bg 1px solid;

      &:after {
        content: "\f107";
        float: right;
        font-weight: $font-light !important;
        font-family: "Font Awesome 5 Pro";
        font-size: 24px;
        color: $color-black;
        position: absolute;
        top: 8px;
        right: 25px;
      }
    }

    .open-toggle {
      width: 100%;
      padding: 20px 0;
    }
  }
}

/**
 * 1) Block
 */

/**
 * 2) Element
 */

/**
 * 3) Modifier
 */

