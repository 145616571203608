/**
 * Base/defaults stylesheet
 *
 * Table of contents
 * 1) Box-sizing
 * 2) HTML
 * 3) Body
 */

/**
 * 1) Box-sizing
 */

*,
*:before,
*:after {
  box-sizing: inherit;
  webkit-font-smoothing: antialiased;
  outline: none;
}

/**
 * 2) HTML
 */

html {
  font-family: $font-sans;
  line-height: 1.7;
  font-size: 1em;
  box-sizing: border-box;
  overflow-x: hidden;
}

/**
 * 3) Body
 */

body {
  color: $color-text;
  background-color: $color-white;
  padding-top: 75px;
  transition: background-color .3s ease;
  overflow-x: hidden;

  @include respond-min($md) {
    padding-top: 110px;
  }
}

b{
  font-weight: $font-medium;
}

.no-scroll {
  height: 100%;
  overflow: auto;
}

.two-column{
  column-count: 2;
  @include respond-max(991px){
    column-count: 1;
  }
}

.d-flex{
  display: flex;
}

.align-items-center{
  align-items: center;
}

.justify-content-center{
  justify-content: center;
}

.justify-content-between{
  justify-content: space-between;
}

.light-bg{
  background-color: $color-bg;
}