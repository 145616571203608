/**
 * Modules/icons stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */


/**
 * 1) Block
 */

.headlines__calendar {

  .calendar {
    .item {
      text-decoration: none;

      @include respond-max(480px) {
        margin-bottom: 40px !important;
      }

      @include respond-min($md) {
        &:hover {
          .datefrom, .dateto {
            color: $color-white !important;
            background-color: $color-secondary !important;
          }
        }
      }

      .content {
        font-size: 16px;

        p {
          margin-bottom: 3px;
        }

        h3 {
          color: $color-secondary;
          font-weight: $font-bold;
          margin-bottom: 5px;

          a {
            color: $color-secondary;
            font-weight: $font-black;
            text-decoration: none;

            @include respond-min($md) {
              &:hover {
                color: lighten($color-black, 0%);
              }
            }

          }
        }

      }

      .location, .time {
        color: $color-grey;
        font-size: 16px;
        line-height: 26px;

        .fa, .fab, .fal, .far, .fas {
          font-size: 18px;
          margin-right: 10px;
          font-weight: $font-light !important;
        }
      }

      .date {
        max-width: 210px;

        @include respond-max(480px) {
          float: left;

          .dateto, .datefrom {
            .day {
              font-size: 30px !important;
              line-height: 40px;
              height: 35px;
            }

            .month {
              line-height: 18px !important;
              font-size: 16px !important;
            }
          }

        }

        .dateto, .datefrom {
          background-color: $color-primary;
          color: $color-white;
          width: 50%;
          width: 80px;
          float: left;
          text-align: center;
          text-transform: uppercase;
          padding: 20px 0;

          @include respond-between(481px, 991px) {
            width: 78px !important;
          }

          .day {
            clear: both;
            font-weight: $font-bold;
            display: block;
            font-size: 40px;
            line-height: 40px;
            height: 40px;
          }

          .month {
            clear: both;
            display: block;
            line-height: 20px;
            font-size: 20px;
          }
        }

        .datefrom {
          border-radius: 4px 0px 0px 4px;
          width: 88px;

          &.line {
            width: 180px !important;
            border-radius: 4px !important;

            @include respond-between($xs, 767px) {
              width: 165px !important;
            }
            @include respond-between($sm, 991px) {
              width: 165px !important;
            }
          }
        }

        .dateto {
          border-left: 5px solid $color-white;
          width: calc(88px + 5px) !important;
          border-radius: 0 4px 4px 0;

          @include respond-between(481px, 991px) {
            width: 82px !important;
          }
        }
      }

    }
  }

}

.calendar {
  .item {
    text-decoration: none;

    @include respond-max(480px) {
      margin-bottom: 40px !important;
    }

    @include respond-min($md) {
      &:hover {
        .datefrom, .dateto {
          color: $color-white !important;
          background-color: $color-secondary !important;
        }
      }
    }

    .content {
      font-size: 16px;

      p {
        margin-bottom: 3px;
      }

      h3 {
        color: $color-secondary;
        font-weight: $font-bold;
        margin-bottom: 5px;

        a {
          color: $color-secondary;
          font-weight: $font-black;
          text-decoration: none;

          @include respond-min($md) {
            &:hover {
              color: lighten($color-black, 0%);
            }
          }

        }
      }

    }

    .location, .time {
      color: $color-grey;
      font-size: 16px;
      line-height: 26px;

      .fa, .fab, .fal, .far, .fas {
        font-size: 18px;
        margin-right: 10px;
        font-weight: $font-light !important;
      }
    }

    .date {
      max-width: 210px;

      @include respond-max(480px) {
        float: left;

        .dateto, .datefrom {
          .day {
            font-size: 30px !important;
            line-height: 40px;
            height: 35px;
          }

          .month {
            line-height: 18px !important;
            font-size: 16px !important;
          }
        }

      }

      .dateto, .datefrom {
        background-color: $color-primary;
        color: $color-white;
        width: 50%;
        width: 80px;
        float: left;
        text-align: center;
        text-transform: uppercase;
        padding: 20px 0;

        @include respond-between(481px, 991px) {
          width: 78px !important;
        }

        .day {
          clear: both;
          font-weight: $font-bold;
          display: block;
          font-size: 40px;
          line-height: 40px;
          height: 40px;
        }

        .month {
          clear: both;
          display: block;
          line-height: 20px;
          font-size: 20px;
        }
      }

      .datefrom {
        border-radius: 4px 0px 0px 4px;
        width: 88px;

        &.line {
          width: 180px !important;
          border-radius: 4px !important;

          @include respond-between($xs, 767px) {
            width: 165px !important;
          }
          @include respond-between($sm, 991px) {
            width: 165px !important;
          }
        }
      }

      .dateto {
        border-left: 5px solid $color-white;
        width: calc(88px + 5px) !important;
        border-radius: 0 4px 4px 0;

        @include respond-between(481px, 991px) {
          width: 82px !important;
        }
      }
    }

  }
}

.calendar {

  &.detail_calendar {

    .item{
      &:hover {
        .datefrom, .dateto {
          color: $color-white !important;
          background-color: $color-primary !important;
        }
      }
    }

    .lines {
      @include respond-min($sm) {
        border-left: 1px solid lighten($color-grey, 30%);
        padding-left: 20px;
      }
    }

    .date {
      padding-left: 12px;

      .datefrom {
        &.line {
          margin-left: 0 !important;
        }
      }
    }
  }

  .month {
    h3 {
      color: $color-primary !important;
    }

    hr {
      margin-top: 0 !important;
    }
  }

}

/**
 * 2) Element
 */


/**
 * 3) Modifier
 */
