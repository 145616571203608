/**
 * Modules/logo stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */

/**
 * 1) Block
 */

.logo {
  display: block;
  margin-top: 15px;
  transition: all .3s ease;

  svg {
    width: 180px;
    height: 50px;
    transition: all .3s ease;
  }

  @include respond-min($md) {
    display: block;
    margin-top: 10px;

    svg {
      width: 220px;
      height: 60px;
      transition: all .3s ease;
    }
  }
}

/**
 * 2) Element
 */

/**
 * 3) Modifier
 */
