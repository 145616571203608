/**
* Modules/icons stylesheet
*
* Table of contents
* 1) Block
* 2) Element
* 3) Modifier
*/

/**
* 1) Block
*/

.brandslider {
  text-align: center;
  background-color: $color-white;

  .title {
    h2 {
      font-weight: $font-bold;
      color: $color-black;
      font-size: 28px;
    }
  }

  .item {
    display: block;
    position: relative;

    .inner {
      padding: 20px;
      margin:0 10px;
      background-color: $color-white;
    }

    a.image {
      display: block;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      padding-bottom: 75%;
      margin: 0 auto;
    }
  }

}

/**
* 2) Element
*/

/**
* 3) Modifier
*/
