/**
 * Webshop/cms-cart stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */
/**
 * 1) VARS
 */

/**
 * 2) Element
 */

.checkout-address__popup {
  border-radius: 10px;

  @include respond-min($md) {
    min-width: 850px;
  }

  .normal__inputs {
    .radio {
      position: relative;
      display: inline-block;
      margin-right: 15px;
      min-height: 40px;
      line-height: 40px;
      cursor: pointer;

      input {
        visibility: hidden;
        width: 0;
      }

      &.active {

        &:after {
          content: "\f00c";
          font-family: "Font Awesome 5 Pro";
          position: absolute;
          color: $color-text;
          top: 0px;
          left: 3px;
          display: inline-block;
        }

        &:before {
          border: 1px solid $color-text;
        }
      }

      &:before {
        content: "";
        position: relative;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        border: 1px solid $color-bg;
        display: inline-block;
        top: 4px;
      }
    }
  }

  .customer__inputs {

    .radio {
      position: relative;
      display: inline-block;
      margin-right: 5px;
      padding: 0 15px;
      min-height: 40px;
      line-height: 40px;
      border: 1px solid $color-bg;
      cursor: pointer;

      input {
        visibility: hidden;
        width: 0;
      }

      &.active {
        background-color: $color-bg;

        &:after {
          content: "\f00c";
          font-family: "Font Awesome 5 Pro";
          position: absolute;
          color: $color-text;
          top: 0px;
          left: 17px;
          display: inline-block;
        }

        &:before {
          border: 1px solid $color-text;
        }
      }

      &:before {
        content: "";
        position: relative;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        border: 1px solid $color-bg;
        display: inline-block;
        top: 4px;
      }
    }

    .company__fields {
      background-color: $color-bg;
      padding: 20px 25px;
      display: none;

      .inputfield {
        padding-left: 5px;
        padding-right: 5px;
      }

      &.active {
        display: block;
      }
    }
  }

  .address__item {
    border: 1px solid $color-bg;
    border-radius: 8px;
    padding: 15px;

    .address__col {
      border-right: 1px solid $color-bg;

      .inner {
        margin-bottom: 20px;
      }
    }

    .address-edit__col {
      .button__cart__selected, .button__cart__grey {
        // width: 265px;
        //margin-bottom: 5px;
      }
    }
  }

  textarea.error::-webkit-input-placeholder, input.form-control.error::-webkit-input-placeholder, input.keywords.error::-webkit-input-placeholder, input.text.error::-webkit-input-placeholder, input[type=email].error::-webkit-input-placeholder, input[type=password].error::-webkit-input-placeholder, input[type=search].error::-webkit-input-placeholder, input[type=text].error::-webkit-input-placeholder {
    color: #f44336 !important;
  }

  .success__box {
    position: relative;
    background: #add47f;
    padding: 10px;
    color: $color-white;
    margin: 10px 0;
    font-size: 14px;
    border-radius: 5px;
  }

  .info__box {
    position: relative;
    background: #ffe5bf;
    padding: 10px;
    color: #ff9800;
    margin: 10px 0;
    font-size: 14px;
    border-radius: 5px;
  }

  .error__box {
    position: relative;
    padding: 10px;
    background: #faaea8;
    color: $color-white;
    margin: 10px 0;
    font-size: 14px;
    border-radius: 5px;
  }

  .error__text {
    position: relative;
    color: #f44336;
    margin: 10px 0;
    font-size: 14px;
  }

}


.button__cart__grey {
  text-decoration: none;
  text-align: left;
  background: $color-white;
  border: 1px solid $color-grey;
  color: $color-text;
  display: inline-block;
  line-height: 35px;
  min-height: 35px;
  padding: 0 20px 0 20px;
  font-size: 16px;
  transition: all .3s ease;
  cursor: pointer;

  .fa, .fab, .fad, .fal, .far, .fas {
    position: relative;
    margin-right: 10px;
    margin-left: 0 !important;
  }

  @include respond-min($md) {
    &:hover {
      transition: all .3s ease;
      background: $color-white;
      border: 1px solid darken($color-green, 10%);
      color: darken($color-green, 10%);

      .fa, .fab, .fad, .fal, .far, .fas {
        background: none !important;
        border-color: none !important;
        color: $color-text !important;
      }
    }
  }
}

.button__cart__selected {
  text-decoration: none;
  text-align: left;
  background: $color-white;
  border: 1px solid darken($color-green, 10%);
  color: darken($color-green, 10%);
  display: inline-block;
  line-height: 35px;
  min-height: 35px;
  padding: 0 20px 0 20px;
  font-size: 16px;
  transition: all .3s ease;
  cursor: pointer;

  .fa, .fab, .fad, .fal, .far, .fas {
    position: relative;
    margin-right: 10px;
    margin-left: 0 !important;
  }

  @include respond-min($md) {
    &:hover {

      .fa, .fab, .fad, .fal, .far, .fas {
        background: none !important;
        border-color: none !important;
        color: darken($color-green, 10%) !important;
      }

      &.login {
        background: darken($color-green, 10%) !important;
        color: $color-white !important;

        .fa, .fab, .fad, .fal, .far, .fas {
          color: $color-white !important;
        }
      }
    }
  }
}

.cart__button {
  text-decoration: underline;
  color: $color-text;
  padding: 0;
  line-height: 1.2em;
  font-weight: normal;
  background: none;
  border-collapse: collapse;
  border: 0;
  font-size: 14px;
  margin: 20px 0;
  cursor: pointer;
  clear: both;
  display: block;

  &.selected {
    color: $color-primary;
  }

  .fa, .fab, .fad, .fal, .far, .fas {
    position: relative;
    margin-right: 5px;
    margin-left: 0 !important;
  }

  @include respond-min($md) {
    &:hover {
      text-decoration: none !important;

      .fa, .fab, .fad, .fal, .far, .fas {
        background: none !important;
        border-color: none !important;
        color: $color-text !important;
      }
    }
  }
}

.cms__cart {
  clear: both;
  position: relative;

  .guest {
    max-width: 370px;
    width: 100%;
    //margin: 0 auto;

    h4 {
      font-weight: $font-regular !important;
      color: $color-text;
      font-size: 14px !important;
      margin-bottom: 0;

      .fa, .fab, .fal, .far, .fas {
        margin-right: 10px;
      }
    }

    .guest__button {
      background: none !important;
      border: solid 1px $color-text;
      text-decoration: none;
      text-align: center;
      color: $color-text;
      width: 100%;
      display: inline-block;
      line-height: 40px;
      min-height: 40px;
      padding: 0 30px 0 30px;
      font-size: 13px !important;
      text-transform: uppercase;
      font-weight: $font-regular;
      transition: all .3s ease;
      cursor: pointer;
      margin-bottom: 10px;

      @include respond-min($md) {
        &:hover {
          transition: all .3s ease;
          background-color: none;
          color: $color-secondary;
          border-color: $color-secondary;
        }
      }
    }

  }

  .login__container {
    //max-width: 370px;
    width: 100%;
    padding: 30px;
    margin: 0 auto;
    border: 1px solid $color-bg;
    border-radius: 0px;
    overflow: hidden;
    //display: none;

    &.showlogin {
      display: block !important;
    }

    label, .title {
      font-size: 12px;
      text-transform: uppercase;
      display: inline-block;
      background: white;
      position: relative;
      top: 12px;
      padding: 5px;
      height: 20px;
      line-height: 20px;
      left: 10px;
      font-weight: bold;
      color: $color-text;
    }

    .button {
      height: 45px;
      line-height: 45px;
      background-color: $color-green;
      font-size: 16px;
      padding: 0 30px;
      width: 100%;

      @include respond-max(767px) {
        width: 100%;
      }
    }

    input[type=submit] {
      margin-top: 20px;
      cursor: pointer;
      width: 100%;
    }

    .forgotten {

      a {
        font-weight: $font-bold;
        text-transform: uppercase;
        font-size: 11px;

        @include respond-min($md) {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    input[type=text] {
      font-size: 11px;
    }

    *::-webkit-input-placeholder, textarea, textarea.form-control, *::-webkit-input-textarea, -webkit-input-placeholder, input:-moz-placeholder {
      opacity: 0;
      color: transparent !important;
    }

    ::-webkit-input-placeholder {
      opacity: 0;
      color: transparent !important;
    }

    :-moz-placeholder { /* Firefox 18- */
      opacity: 0;
      color: transparent !important;
    }

    ::-moz-placeholder { /* Firefox 19+ */
      opacity: 0;
      color: transparent !important;
    }

    :-ms-input-placeholder {
      opacity: 0;
      color: transparent !important;
    }
  }


  textarea.error::-webkit-input-placeholder, input.form-control.error::-webkit-input-placeholder, input.keywords.error::-webkit-input-placeholder, input.text.error::-webkit-input-placeholder, input[type=email].error::-webkit-input-placeholder, input[type=password].error::-webkit-input-placeholder, input[type=search].error::-webkit-input-placeholder, input[type=text].error::-webkit-input-placeholder {
    color: #f44336 !important;
  }

  .success__box {
    position: relative;
    background: #add47f;
    padding: 10px;
    color: $color-white;
    margin: 10px 0;
    font-size: 14px;
    border-radius: 5px;
  }

  .info__box {
    position: relative;
    background: #ffe5bf;
    padding: 10px;
    color: #ff9800;
    margin: 10px 0;
    font-size: 14px;
    border-radius: 5px;

    &.clickthis {
      margin: 0 0 10px 0 !important;
      cursor: pointer;
    }
  }

  .info__box {
    position: relative;
    background: #ffe5bf;
    padding: 10px;
    color: #ff9800;
    margin: 10px 0;
    font-size: 14px;
    border-radius: 5px;

    &.clickthis {
      margin: 0 0 10px 0 !important;
      cursor: pointer;
      background: #e4f1f8;
      color: #2196F3;
      text-align: center;
      font-weight: $font-bold;

      @include respond-min($md) {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .error__text {
    position: relative;
    color: #f44336;
    margin: 10px 0;
    font-size: 14px;
  }

  a:not([class]) {
    color: $color-text;
    text-decoration: underline;

    @include respond-min($md) {
      &:hover {
        text-decoration: none;
      }
    }
  }

  .cart__totals {
    .inner {
      .title {
        //text-transform: uppercase;
        //font-weight: $font-bold;
        font-size: 14px;
      }

      .data {
        font-weight: $font-bold;
        font-size: 16px;
      }

      .subtotal {
        margin-bottom: 20px;
      }

      .discount__price .title, .discount__price .data {
        font-weight: $font-light;
        text-transform: lowercase;

        .remove {
          color: $color-grey;
          text-decoration: none;
          position: relative;
          top: 1px;

          @include respond-min($md) {
            &:hover {
              color: $color-text;
            }
          }
        }
      }

      .vat__prices, .vat__percentage {
        .title, .data {
          font-weight: $font-light !important;
        }
      }

      .total {
        .title {
          font-size: 18px;

          .vat__title {
            margin-left: 3px;
            font-weight: $font-light !important;
            font-size: 11px !important;
            text-transform: none !important;

            @include respond-max(400px) {
              display: block;
              margin-left: 0px;
            }
          }
        }

        .data {
          font-size: 20px;
        }
      }
    }
  }
}

.cms__cart__overview {
  .inner {
    background-color: $color-bg;
  }

  @include respond-max(991px) {
    .cms__cart__match {
      height: auto !important;
    }
  }

  hr {
    border-color: darken($color-bg, 10%);
  }

  > .inner {
    padding: 15px;

    .cart__title {
      border-bottom: 1px solid darken($color-bg, 10%);

      p {
        margin-bottom: 10px;
        font-size: 14px;
      }
    }

    .cart__articles {
      .item {
        padding: 20px 0;
        border-bottom: 1px solid darken($color-bg, 10%);
        display: block;
        overflow: hidden;

        p {
          color: $color-text;
          font-size: 14px !important;
          margin-bottom: 0;
        }

        h3 {
          font-size: 16px !important;
          color: $color-text;
          font-weight: $font-bold !important;
          margin-bottom: 0;
        }

        h4 {
          font-size: 14px !important;
          color: $color-text;
          font-weight: $font-light !important;
          margin-bottom: 5px;
        }

        .article__col {
          @include respond-max(599px) {
            width: 100% !important;

            .content__col {
              width: 100% !important;
            }
          }
        }

        .image__col {
          @include respond-max(599px) {
            display: none;
          }

          .image {
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            padding-bottom: 75%;
            display: block;
          }
        }

        .price__col {
          line-height: 16px;

          .vat__title {
            margin-left: 10px;
            font-weight: $font-light !important;
            font-size: 11px !important;
            text-transform: none !important;
          }

          .current__price {
            font-size: 14px;
            width: 100%;
            font-weight: $font-bold;
            margin-top: 6px;
          }

          .old__price {
            color: $color-grey;
            text-decoration: line-through;
            font-size: 14px;
            width: 100%;
          }
        }

      }
    }
  }
}

.cart__col {
  color: $color-text;

  .line {
    border-bottom: 1px solid $color-bg;
  }

  span.green {
    color: $color-green;
  }

  .heading {
    background-color: $color-bg;
    padding: 8px;

    .col {
      @include respond-max(599px) {
        &:nth-child(1) {
          display: none;
        }
        &:nth-child(2) {
          float: left;
        }
        &:nth-child(3) {
          float: right;
          text-align: right;
        }
      }
    }

    .title {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: $font-bold;
    }
  }

  .content__col {
    h3 {
      font-size: 16px !important;
      color: $color-text;
      font-weight: $font-bold !important;
      margin-bottom: 0;

      a {
        text-decoration: none !important;

        @include respond-min($md) {
          &:hover {
            color: lighten($color-text, 20%);
          }
        }
      }
    }

    h4 {
      font-weight: $font-regular !important;
      color: $color-text;
      font-size: 16px !important;
      margin-bottom: 0;

      .fa, .fab, .fal, .far, .fas {
        margin-right: 10px;
      }
    }

    p {
      color: $color-text;
      font-size: 13px !important;
      margin-bottom: 0;
    }
  }

  .cart__items {
    .item {
      padding: 20px 0;
      border-bottom: 1px solid $color-bg;
      display: block;
      overflow: hidden;

      .flex {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: left;
        -ms-flex-pack: left;
        justify-content: left;
        flex-flow: wrap;
        align-content: center;
      }

      .article__col {
        @include respond-max(599px) {
          width: 100% !important;

          .content__col {
            width: 100% !important;
          }
        }
      }

      .image__col {
        @include respond-max(599px) {
          display: none;
        }

        .image {
          background-position: center;
          background-size: contain;
          padding-bottom: 75%;
          background-repeat: no-repeat;
          display: block;
        }
      }

      .price__col {
        line-height: 18px;

        @include respond-max(599px) {
          width: 50% !important;
          margin-top: 15px;
          text-align: right;
        }

        .current__price {
          font-size: 16px;
          width: 100%;
          font-weight: $font-bold;
        }

        .old__price {
          color: $color-grey;
          text-decoration: line-through;
          font-size: 16px;
          width: 100%;
        }
      }

      .amount__col {
        @include respond-max(599px) {
          width: 50% !important;
          margin-top: 15px;
        }

        .remove {
          margin-left: 0px;
          float: left;

          a, button {
            text-decoration: none;
            color: $color-grey;
            cursor: pointer;

            @include respond-max(991px) {
              color: $color-text;
            }

            @include respond-min($md) {
              &:hover {
                color: $color-text;
              }
            }
          }
        }

        .amount {
          float: left;

          .qtyminus, .qtyplus {
            max-width: 40px;
            top: 1px;
            position: relative;
            line-height: 42px;
            min-width: 24px;
            min-height: 42px;
            padding: 0 5px;
            float: left;
            border: 1px solid $color-bg;
            color: $color-black;
            background-color: $color-white;
            font-weight: $font-light;
            cursor: pointer;

            @include respond-min($md) {
              &:hover {
                background-color: $color-bg;
              }
            }
          }

          input {
            position: relative;
            max-width: 55px;
            margin-top: 1px;
            padding: 0 4px;
            height: 44px;
            line-height: 45px;
            float: left;
            font-size: 13px !important;
            background-color: $color-white;
            text-align: center;
            border-top: 1px solid $color-bg;
            border-bottom: 1px solid $color-bg;
            border-left: 0 !important;
            border-right: 0 !important;
          }
        }
      }

    }
  }

  .cart__gift {
    .inner {

      .item {
        text-decoration: none;

        h3 {
          font-size: 14px !important;
          font-weight: $font-regular !important;
        }

        @include respond-min($md) {
          &:hover {
            .inner {
              border: 1px solid $color-grey;

              .select__gift {
                .circle {
                  border: 1px solid $color-grey !important;
                }
              }
            }
          }
        }

        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;

          @include respond-min($md) {
            &:hover {
              .inner {
                border: 1px solid $color-bg !important;

                .select__gift {
                  .circle {
                    border: 1px solid $color-bg !important;
                  }
                }
              }
            }
          }
        }

        &.selected {
          .inner {
            border: 1px solid $color-text;

            .select__gift {
              .circle {
                border: 1px solid $color-text !important;

                &:after {
                  content: "";
                  position: absolute;
                  width: 14px;
                  height: 14px;
                  border-radius: 100%;
                  background-color: $color-text;
                  top: calc(50% - 7px);
                  left: calc(50% - 7px);
                }
              }
            }
          }
        }

        .flex {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -webkit-justify-content: left;
          -ms-flex-pack: left;
          justify-content: left;
          flex-flow: wrap;
          align-content: center;
        }

        .inner {
          padding: 15px 15px 15px 20px;
          border: 1px solid $color-bg;

          .select__gift {
            float: left;
            width: 20px;

            .circle {
              position: relative;
              width: 20px;
              height: 20px;
              border-radius: 100%;
              border: 1px solid $color-bg;
            }
          }

          .article__col {
            width: calc(100% - 20px);
            float: left;
          }

          .image__col {
            @include respond-max(599px) {
              display: none;
            }

            .image {
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;
              padding-bottom: 75%;
              display: block;
            }
          }

        }
      }
    }
  }

  .payment__method {

    .item {
      text-decoration: none;
      cursor: pointer;

      @include respond-min(1400px) {
        width: 50% !important;
      }

      h3 {
        font-weight: $font-regular !important;
        font-size: 14px !important;
      }

      p {
        clear: both;
        display: block;
        width: 100%;
        color: #aeaeae;
      }

      @include respond-min($md) {
        &:hover {
          .inner {
            border: 1px solid $color-grey;

            .select__gift {
              .circle {
                border: 1px solid $color-grey !important;
              }
            }
          }
        }
      }

      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;

        @include respond-min($md) {
          &:hover {
            .inner {
              border: 1px solid $color-bg !important;

              .select__gift {
                .circle {
                  border: 1px solid $color-bg !important;
                }
              }
            }
          }
        }
      }

      &.selected {
        .inner {
          border: 1px solid $color-text;

          .select__method {
            .circle {
              border: 1px solid $color-text !important;

              &:after {
                content: "";
                position: absolute;
                width: 14px;
                height: 14px;
                border-radius: 100%;
                background-color: $color-text;
                top: calc(50% - 7px);
                left: calc(50% - 7px);
              }
            }
          }
        }
      }

      .flex {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: left;
        -ms-flex-pack: left;
        justify-content: left;
        flex-flow: wrap;
        align-content: center;
      }

      .inner {
        padding: 15px 15px 15px 20px;
        border: 1px solid $color-bg;

        .select__method {
          float: left;
          width: 20px;

          .circle {
            position: relative;
            width: 20px;
            height: 20px;
            border-radius: 100%;
            border: 1px solid $color-bg;
          }
        }

        .article__col {
          width: calc(100% - 20px);
          float: left;

          select {
            margin-bottom: 0 !important;
          }
        }

        .image {
          max-width: 30px;
          float: left;
          margin-right: 10px;
        }

      }
    }

  }

  .shipping__method {
    .item {
      text-decoration: none;

      @include respond-between($lg, 1400px) {
        width: 50% !important;
      }

      h3 {
        font-weight: $font-regular !important;
      }

      @include respond-min($md) {
        &:hover {
          .inner {
            border: 1px solid $color-grey;

            .select__gift {
              .circle {
                border: 1px solid $color-grey !important;
              }
            }
          }
        }
      }

      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;

        @include respond-min($md) {
          &:hover {
            .inner {
              border: 1px solid $color-bg !important;

              .select__gift {
                .circle {
                  border: 1px solid $color-bg !important;
                }
              }
            }
          }
        }
      }

      &.selected {
        .inner {
          border: 1px solid $color-text;

          .select__gift {
            .circle {
              border: 1px solid $color-text !important;

              &:after {
                content: "";
                position: absolute;
                width: 14px;
                height: 14px;
                border-radius: 100%;
                background-color: $color-text;
                top: calc(50% - 7px);
                left: calc(50% - 7px);
              }
            }
          }
        }
      }

      .flex {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: left;
        -ms-flex-pack: left;
        justify-content: left;
        flex-flow: wrap;
        align-content: center;
      }

      .inner {
        padding: 15px 15px 15px 20px;
        border: 1px solid $color-bg;

        .select__gift {
          float: left;
          width: 20px;

          .circle {
            position: relative;
            width: 20px;
            height: 20px;
            border-radius: 100%;
            border: 1px solid $color-bg;
          }
        }

        .article__col {
          width: calc(100% - 20px);
          float: left;
        }

        .icon__col {
          .icon {
            color: $color-text;
            font-size: 26px;
          }
        }

      }
    }
  }

  .cart__vouchers {
    h4 {
      margin-bottom: 10px;
    }

    input[type="text"] {
      margin-bottom: 0 !important;
    }
  }

  .cart__proceed-order {
    .previous {
      button {
        text-decoration: none;
        text-align: center;
        background: none;
        border: 1px solid lighten($color-text, 30%);
        color: lighten($color-text, 30%);
        display: inline-block;
        height: 50px;
        line-height: 50px;
        padding: 0 20px 0 20px;
        font-size: 16px;
        transition: all .3s ease;
        cursor: pointer;

        .fa, .fab, .fad, .fal, .far, .fas {
          transition: all .3s ease;
          position: relative;
          margin-left: 35px;
          left: 0;
        }

        @include respond-min($md) {
          &:hover {
            transition: all .3s ease;
            border: 1px solid lighten($color-text, 0%);
            color: lighten($color-text, 0%);

            .fa, .fab, .fad, .fal, .far, .fas {
              transition: all .3s ease;
              position: relative;
              left: 10px;
            }
          }
        }
      }
    }

    .button {
      height: 50px;
      line-height: 50px;
      background-color: $color-green;
      border: 1px solid $color-green;
      font-size: 16px;
      padding: 0 30px;

      @include respond-max(767px) {
        width: 100%;
      }
    }
  }

  .cart__steps {
    counter-reset: cart--steps;

    .cart__step {
      h2 {
        font-weight: $font-regular !important;
        font-size: 22px !important;

        &:before {
          text-align: center;
          display: inline-block;
          counter-increment: cart--steps;
          content: counter(cart--steps);
          height: 40px;
          line-height: 40px;
          width: 40px;
          border-radius: 100%;
          margin-right: 10px;
          font-size: 18px !important;
          color: $color-white;
          background-color: $color-text;
          font-weight: $font-regular !important;
        }
      }

      .input__fields {
        padding: 0 5px;

        .inputfield {
          padding-left: 5px;
          padding-right: 5px;
        }
      }

      .invoice__address, .register__account {

        .checkbox {
          position: relative;
          display: inline-block;
          margin-right: 5px;
          padding: 0 15px;
          min-height: 40px;
          line-height: 40px;
          border: 1px solid $color-bg;
          cursor: pointer;

          input {
            visibility: hidden;
            width: 0;
          }

          &.active {
            background-color: $color-bg;

            &:after {
              content: "\f00c";
              font-family: "Font Awesome 5 Pro";
              position: absolute;
              color: $color-text;
              top: 0px;
              left: 17px;
              display: inline-block;
            }

            &:before {
              border: 1px solid $color-text;
            }
          }

          &:before {
            content: "";
            position: relative;
            width: 20px;
            height: 20px;
            border: 1px solid $color-bg;
            display: inline-block;
            top: 4px;
          }
        }

        .hidden__fields {
          background-color: $color-bg;
          padding: 20px 25px;
          display: none;

          .data {
            sup {
              color: $color-grey;
            }

            p {
              margin-bottom: 0;
            }
          }

          .inputfield {
            padding-left: 5px;
            padding-right: 5px;
          }

          &.active {
            display: block;
          }
        }

      }

      .normal__inputs {
        .radio {
          position: relative;
          display: inline-block;
          margin-right: 15px;
          min-height: 40px;
          line-height: 40px;
          cursor: pointer;

          input {
            visibility: hidden;
            width: 0;
          }

          &.active {

            &:after {
              content: "\f00c";
              font-family: "Font Awesome 5 Pro";
              position: absolute;
              color: $color-text;
              top: 0px;
              left: 3px;
              display: inline-block;
            }

            &:before {
              border: 1px solid $color-text;
            }
          }

          &:before {
            content: "";
            position: relative;
            width: 20px;
            height: 20px;
            border-radius: 100%;
            border: 1px solid $color-bg;
            display: inline-block;
            top: 4px;
          }
        }
      }

      .customer__inputs {

        .radio {
          position: relative;
          display: inline-block;
          margin-right: 5px;
          padding: 0 15px;
          min-height: 40px;
          line-height: 40px;
          border: 1px solid $color-bg;
          cursor: pointer;

          input {
            visibility: hidden;
            width: 0;
          }

          &.active {
            background-color: $color-bg;

            &:after {
              content: "\f00c";
              font-family: "Font Awesome 5 Pro";
              position: absolute;
              color: $color-text;
              top: 0px;
              left: 17px;
              display: inline-block;
            }

            &:before {
              border: 1px solid $color-text;
            }
          }

          &:before {
            content: "";
            position: relative;
            width: 20px;
            height: 20px;
            border-radius: 100%;
            border: 1px solid $color-bg;
            display: inline-block;
            top: 4px;
          }
        }

        .company__fields {
          background-color: $color-bg;
          padding: 20px 25px;
          display: none;

          .inputfield {
            padding-left: 5px;
            padding-right: 5px;
          }

          &.active {
            display: block;
          }
        }
      }
    }
  }

  select, input[type="text"], input[type="password"], input[type="email"], input.text, input.form-control, input[type="number"] {
    height: 50px;
    line-height: 50px;
    float: left;
    background-color: $color-white;
    border: 1px solid $color-bg;
    margin-bottom: 8px;
    position: relative;
    padding: 15px 15px 0 15px !important;
  }

  textarea, textarea.form-control {
    background-color: $color-white;
    border: 1px solid $color-bg;
    font-size: 13px !important;
    position: relative;
  }

  .floating-label {
    position: absolute;
    pointer-events: none;
    left: 20px;
    top: 15px;
    transition: 0.2s ease all;
    color: $color-grey;
    color: blue;
    font-size: 16px;
  }

  input:focus ~ .floating-label,
  input:not(:focus).has-value ~ .floating-label {
    top: 7px;
    left: 20px;
    font-size: 10px;
    opacity: 1;
    color: $color-grey;
  }

  //
  //input:not(:focus).has-value.error{
  //  color: red;
  //  background-image: url("times-light.svg");
  //  background-repeat: no-repeat;
  //  background-position: 95% center;
  //  background-size: 15px;
  //}
  //
  //input:not(:focus).has-value.success{
  //  color: green;
  //  background-image: url("check-regular.svg");
  //  background-repeat: no-repeat;
  //  background-position: 95% center;
  //  background-size: 20px;
  //}

  .inputText {
    float: left;
    width: 100%;
    padding: 15px 10px 10px;
    margin: 0;
    border: 1px solid #000;
    background: #fff;
    line-height: 20px;
  }

}

.usp__col {
  .inner {
    padding: 40px;
    border: 1px solid $color-bg;

    @include respond-max(991px) {
      padding: 25px;
    }

    ul {
      margin-left: 30px;

      li {
        font-size: 0.8em;
        position: relative;

        strong {
          font-weight: $font-bold;
        }

        &:before {
          content: "\f058";
          font-family: "Font Awesome 5 Pro";
          color: $color-green;
          font-weight: 300;
          margin-left: -30px;
          width: 30px;
          font-size: 20px;
          display: inline-block;
          top: 2px;
          position: relative;
        }
      }
    }
  }
}

.ui-datepicker {
  border: 1px solid #e6e6e6 !important;
  width: 100% !important;
  box-shadow: none;
  display: none;

  .ui-state-hover {
    background-color: $color-black;
    color: $color-white;
  }

  .ui-datepicker-calendar {
    width: 100%;
    border-spacing: 0;
    padding: 5px;

    tr {
      &.ui-state-active {
        padding: 2px !important;
        text-decoration: none !important;
        background-color: #d6e8c2 !important;
        color: $color-white !important;
        border: 0 !important;
      }
    }

    td {
      text-align: center;
      padding: 2px;

      span {
        padding: 2px;
      }

      .ui-button.ui-state-active:hover, .ui-button:active, .ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-state-hover {
        padding: 2px !important;
        text-decoration: none !important;
        background-color: #a0d466 !important;
        color: $color-white !important;
        border: 0 !important;
      }

      .ui-state-default {
        padding: 2px !important;
        text-decoration: none !important;
        background: $color-bg;
        color: $color-text;
        text-align: center !important;
        display: block;
        border: 0;
      }
    }
  }

  .ui-datepicker-header, .ui-datepicker-title {
    min-height: 40px !important;
    line-height: 40px !important;
    padding: 0 !important;
    border: 0px !important;
    text-align: center;
  }

  .ui-datepicker-group-first {
    width: calc(50% - 0px) !important;
    float: left !important;

    table {
      margin-bottom: 5px !important;
    }

    @include respond-max(786px) {
      width: 100% !important;
      float: none !important;
    }
  }

  .ui-datepicker-group-last {
    width: calc(50% - 0px) !important;
    float: right !important;

    table {
      margin-bottom: 5px !important;
    }

    @include respond-max(786px) {
      width: 100% !important;
      float: none !important;
    }
  }

  .ui-datepicker-prev, .ui-datepicker-next {
    top: 0 !important;
    border-radius: 0;
    font-weight: 400 !important;
  }

  .ui-datepicker-prev-hover, .ui-datepicker-next-hover {
    top: 0 !important;
    font-weight: 400 !important;
    background-color: darken($color-bg, 50%);
  }

  .ui-datepicker-prev {
    background-color: darken($color-bg, 20%);
    min-height: 40px;
    line-height: 40px;
    text-align: center;
    width: 30px;
    display: block;
    left: 0 !important;
    float: left;
    margin-right: 10px;

    .ui-icon {
      display: none;
    }

    &:before {
      content: '\f104';
      font-family: "Font Awesome 5 Pro";
      color: $color-black;
    }
  }

  .ui-datepicker-next {
    background-color: darken($color-bg, 20%);
    min-height: 40px;
    line-height: 40px;
    text-align: center;
    right: 0px !important;
    width: 30px;
    display: block;
    float: right;
    margin-left: 10px;

    .ui-icon {
      display: none;
    }

    &:before {
      content: '\f105';
      font-family: "Font Awesome 5 Pro";
      color: $color-black;
    }
  }

  .ui-datepicker-prev-hover {
    background-color: darken($color-bg, 30%) !important;
    min-height: 40px;
    line-height: 40px;
    text-align: center;
    width: 30px;
    display: block;
    float: left;
    margin-right: 10px;
    border: 0px !important;

    &:before {
      content: '\f104';
      font-family: "Font Awesome 5 Pro";
      color: $color-black;
    }
  }

  .ui-datepicker-next-hover {
    background-color: darken($color-bg, 30%) !important;
    min-height: 40px;
    line-height: 40px;
    text-align: center;
    width: 30px;
    display: block;
    float: right;
    margin-left: 10px;
    border: 0px !important;

    &:before {
      content: '\f105';
      font-family: "Font Awesome 5 Pro";
      color: $color-black;
    }
  }
}

/**
 * 3) Modifier
 */
