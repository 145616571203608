/**
 * Utilities/margin stylesheet
 */

@each $size, $scale in $spacing-sizes {
    .u-margin,
    %u-margin {
        margin: $baseline !important;
    }

    @each $breakpoint in $breakpoints {
        $key: nth($breakpoint, 1);
        $value: nth($breakpoint, 2);

        .u-margin--#{$value},
        %u-margin--#{$value} {
            @include respond-min(#{$key}) {
                margin: $baseline !important;
            }
        }
    }

    @each $edge, $sub in $spacing-edges {
        .u-margin-#{$edge},
        %u-margin-#{$edge} {
            @if $sub {
                @each $subedge in $sub {
                    margin-#{$subedge}: $baseline !important;
                }
            } @else {
                margin-#{$edge}: $baseline !important;
            }
        }

        @each $breakpoint in $breakpoints {
            $key: nth($breakpoint, 1);
            $value: nth($breakpoint, 2);

            .u-margin-#{$edge}--#{$value},
            %u-margin-#{$edge}--#{$value} {
                @include respond-min(#{$key}) {
                    @if $sub {
                        @each $subedge in $sub {
                            margin-#{$subedge}: $baseline !important;
                        }
                    } @else {
                        margin-#{$edge}: $baseline !important;
                    }
                }
            }
        }
    }
}



@each $size, $scale in $spacing-sizes {
    .u-margin-#{$size},
    %u-margin-#{$size} {
        margin: ($baseline * $scale) !important;
    }

    @each $breakpoint in $breakpoints {
        $key: nth($breakpoint, 1);
        $value: nth($breakpoint, 2);

        .u-margin-#{$size}--#{$value},
        %u-margin-#{$size}--#{$value} {
            @include respond-min(#{$key}) {
                margin: ($baseline * $scale) !important;
            }
        }
    }

    @each $edge, $sub in $spacing-edges {
        .u-margin-#{$edge}-#{$size},
        %u-margin-#{$edge}-#{$size} {
            @if $sub {
                @each $subedge in $sub {
                    margin-#{$subedge}: ($baseline * $scale) !important;
                }
            } @else {
                margin-#{$edge}: ($baseline * $scale) !important;
            }
        }

        @each $breakpoint in $breakpoints {
            $key: nth($breakpoint, 1);
            $value: nth($breakpoint, 2);

            .u-margin-#{$edge}-#{$size}--#{$value},
            %u-margin-#{$edge}-#{$size}--#{$value} {
                @include respond-min(#{$key}) {
                    @if $sub {
                        @each $subedge in $sub {
                            margin-#{$subedge}: ($baseline * $scale) !important;
                        }
                    } @else {
                        margin-#{$edge}: ($baseline * $scale) !important;
                    }
                }
            }
        }
    }
}
