/**
 * Webshop/filters stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */
/**
 * 1) Block
 */

/**
 * 2) Element
 */

.cms-shop-menu-content, #shop_menu_content, .shop__menu {
  .content__menu {
    text-decoration: none;
    text-align: center;

    @include respond-min($md) {
      .inner {
        &:hover {

          .image {
            -webkit-transform: scale(1.05);
            -ms-transform: scale(1.05);
            transform: scale(1.05);
            position: relative;
            transition: all .3s ease;
          }
        }
      }
    }

    .inner {
      background-color: $color-white;
      box-shadow: 5px 5px 39px rgba($color-black, .03);
      overflow: hidden;
      margin: 0 auto;
      max-width: 320px;
      padding: 0 20px;

      .image__col {
        overflow: hidden;
        padding: 15px;
        position: relative;
      }

      .info {
        h2 {
          font-size: 22px;
          margin: 15px 15px 0 15px;
          color: $color-text;

          .webshop & {
            font-size: 18px;
          }
        }
      }

      .image {
        transition: all .3s ease;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        display: block;
        padding-bottom: 75%;
      }
    }

  }
}

.shop__menu {
  position: relative;

  h2 {
    font-size: 42px;
    margin-bottom: 5px;

    @include respond-max($sm) {
      font-size: 24px;
    }
  }

  p {
    font-size: 28px;
    margin-bottom: 8px;
    line-height: 1.2em;

    @include respond-max($sm) {
      font-size: 20px;
    }
  }

  .content__menu {
    text-decoration: none;
    text-align: center;

    @include respond-max(320px) {
      width: 100% !important;
    }

    @include respond-min($md) {
      .inner {
        &:hover {

          .image {
            -webkit-transform: scale(1.05);
            -ms-transform: scale(1.05);
            transform: scale(1.05);
            position: relative;
            transition: all .3s ease;
          }
        }
      }
    }

    .inner {
      background-color: $color-white;
      box-shadow: 5px 5px 39px rgba($color-black, .03);
      overflow: hidden;
      padding: 0 20px;

      @include respond-max(480px) {
        padding: 0 10px;
      }

      @include respond-max(991px) {
        margin: 0 auto;
        max-width: 320px;
      }

      .image__col {
        overflow: hidden;
        padding: 15px;
        position: relative;
      }

      .info {
        h2 {
          font-size: 22px;
          margin: 15px 15px 0 15px;
          color: $color-text;

          @include respond-max(767px) {
            font-size: 20px;
          }
          @include respond-max(480px) {
            font-size: 12px;
          }
        }
      }

      .image {
        transition: all .3s ease;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        display: block;
        padding-bottom: 75%;
      }
    }

  }
}

/**
 * 3) Modifier
 */
