/**
 * Layout/content stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */

/**
 * 1) Block
 */

.breadcrumbs{
  a{
    font-size: 18px;
    text-transform: uppercase;
  }
}

.box-shadow{
  box-shadow: 0 1.3333333333rem 2.6666666667rem rgba(0,0,0,.15);
}

.back-fade{
  background-image: url(/assets/dist/img/pattern__dark.png);
  background-size: 1.8rem;
  background-position: bottom;
  background-repeat: repeat-x;
  background-color: #f1f1f1;
}

.reservatie-wrapper{
  margin-top: -100px;
  @include respond-max(767px){
    margin-top: -50px;
  }
  #block__search-date{
    background-color: $color-primary;
    color: $color-white;
    padding: 30px;
  }
  #block__search-room{
    background-color: $color-white;
    color: $color-black;
    padding: 30px;
  }
  .block-shadow{
    box-shadow: 0 1.3333333333rem 2.6666666667rem rgba(0,0,0,.15);
  }
  .search-block{
    form{
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-end;

      @include respond-max(767px){
        display: block;
      }
      .button{
        &:hover{
          i{
            left: 0!important;
          }
        }
      }
    }
    label{
      margin-right: 1rem;
      width: calc(50% - 2.3333333333rem);
      text-align: left;

      @include respond-max(767px){
        width: 100%!important;
      }
      input[type="date"]{
        display: block;
        box-sizing: border-box;
        width: 100%;
        height: 2.6rem;
        margin: 0 0 1.0666666667rem;
        padding: .5333333333rem;
        border: 1px solid #f1f1f1;
        border-radius: 3px;
        background-color: #f1f1f1;
        box-shadow: inset 0 1px 2px rgba(10,10,10,.1);
        font-family: inherit;
        font-size: 1.0666666667rem;
        font-weight: 400;
        line-height: 1.5;
        color: #0a0a0a;
        transition: box-shadow .5s,border-color .25s ease-in-out;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }
    }
    button {
      background-color: #006e9d;
      color: #fff;
      display: inline-block;
      vertical-align: middle;
      margin: 0 0 1rem 0;
      font-family: Lusitana, serif;
      padding: .85em 1em;
      -webkit-appearance: none;
      border: 1px solid transparent;
      border-radius: 3px;
      transition: background-color .25s ease-out, color .25s ease-out;
      font-size: .9rem;
      line-height: 1;
      text-align: center;
      cursor: pointer;
    }
  }
}

#usps-horizontal  {
  ul{
    list-style: none;
    padding: 0;
    margin: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    font-size: 1.0666666667rem;
    justify-content: space-around;
    li{
      font-size: 16px;
      &:before{
        content: "";
        display: inline-block;
        -moz-osx-font-smoothing: grayscale;
        color: #768b77;
        margin-right: 10px;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        height: 16px;
        width: 10px;
        border-bottom: 4px solid #768b77;
        border-right: 4px solid #768b77;
      }
    }
  }
}

.waardering-wrapper{
  .row{
    @include respond-max(767px){
      display: block!important;
    }
  }
  .widget-item{
    max-width: 150px;
    margin: 10px;
    width: 150px;
    padding: 0;

    @include respond-max(767px){
      margin: 10px auto;
    }
  }

}

.item-wrapper{
  .item{
    border-radius: 10px;
    .inner{
      transition: all 0.3s ease-in;
      .image__col{
        min-height: 400px;
        overflow: hidden;
        border-radius: 5px 5px 0 0;
        .image{
          min-height: 400px;
          width: 100%;
          height: 100%;
          display: block;
          background-size: cover;
          transition: all 0.3s ease-in;
        }
      }
      .info__col{
        padding: 25px;
        padding-bottom: 70px;
        background: #fff;
        border-radius: 0 0 5px 5px;
        h4{
          a{
            color: $color-black;
          }
        }
        p{
          font-size: 14px;
        }
        ul{
          list-style: none;
          li{
            font-size: 14px;
            &:before{
              content: "";
              display: inline-block;
              -moz-osx-font-smoothing: grayscale;
              color: #768b77;
              margin-right: 10px;
              -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
              height: 12px;
              width: 8px;
              border-bottom: 3px solid #768b77;
              border-right: 3px solid #768b77;
              top: 3px;
            }
          }
        }
        .button{
          bottom: 25px;
          position: absolute;
        }
      }
    }
    &:hover{
      > .inner{
        box-shadow: 0 -3px 15px rgba(0,0,0,0.2);
        .image__col{
          .image{
            transform: scale(1.1);
          }
        }
      }
    }
  }
}

.bedden{
  background-color: #767676;
  color: #fff;
  padding: 5px 10px;
  font-size: 12px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.vacatures{
  #brasserie-navigation{
    width: 270px;
    .cookie-inverse{
      display: none!important;
    }
    > .nav__sub {
      > .nav__item {
        border-bottom: 0!important;
        > .nav__link {
          display: none !important;
        }
        > ul{
          margin-left: 0!important;
        }
      }
    }
  }
}

#brasserie-navigation{
  width: 210px;
  padding: 20px;
  margin-bottom: 20px;
  background: $color-white;
  z-index: 10;
  .nav__sub{
    display: block!important;
    list-style: none!important;
    margin-left: 0!important;
    li{
      border-bottom: 1px solid lightgray;
      width: 100%;
      &:before{
        content: '';
      }
      a{
        display: inline-block;
        padding: 5px 0;
        width: 100%;
        color: $color-black;

        text-decoration: none;
        &:hover{
          color: $color-primary;
          font-weight: $font-bold;
        }
        &:last-child{
          border-bottom: none;
        }
      }
      &.selected{
        a{
          color: $color-primary;
          font-weight: bold;
        }
      }
    }
  }

}

.contact-welkom-img{
  max-width: 800px!important;
  margin: 0 auto;
  display: block;
}

.white-bg-section{
  background: #fff;
  padding: 25px;
}

.kamer-info{
  hr{
    height: 1px;
    width: 100%;
    background-color: grey;
    margin-top: 10px;
  }
  .reservation{
    margin-top: 20px;
    padding: 25px 50px;
    background-color: $color-primary;
    color: $color-white;
    @include respond-max(767px){
     display: block;
    }
    .price{
      font-size: 15px;
      b{
        margin-left: 10px;
        font-size: 30px;
        font-family: Lusitana,Helvetica,Roboto,Arial, serif;
        font-weight: 600;
      }
    }
    .button{
      margin: 10px;
      font-size: 20px;
      padding: 10px 40px;
      &:hover{
        background-color: #0080b6;
      }
    }
  }
  .button{
    margin-top: 10px;
    margin-bottom: 10px;
    i{
      margin-right: 10px;
    }
    &:hover{
      i{
        left: 0!important;
      }
    }
  }
  ul{
    li{
      text-indent: -1em;
      padding-left: 1em;
      &:before{
        content: "";
        display: inline-block;
        -moz-osx-font-smoothing: grayscale;
        color: #768b77;
        margin-right: 10px;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        height: 12px;
        width: 8px;
        border-bottom: 3px solid #768b77;
        border-right: 3px solid #768b77;
        top: 3px;
      }
    }
  }
}

.content {
  .content__col, &.content__col {
    color: $color-text;

    table:not(.auto) {
      width: 100%;
      border: 0px !important;
      border-collapse: collapse !important;
      border-spacing: 0px !important;

      tbody {
        vertical-align: top;
      }
      th, td {
        text-align: left;
        border: 0px !important;
        border-collapse: collapse !important;
        border-spacing: 0px !important;
        padding: 4px;

        @include respond-max(480px) {
          display: inline-block !important;
        }

      }
      tr {
        border-bottom: solid 1px #f1f1f1;
        &:last-child {
          border: 0px;
        }
      }
    }

    img{
      & + h1 {
        margin-top: 20px !important;
      }
      & + h2 {
        margin-top: 20px !important;
      }
      & + h3 {
        margin-top: 20px !important;
      }
      & + strong {
        margin-top: 20px !important;
        display: inline-block;
      }
    }

    p, ul {
      & + h1 {
        margin-top: 20px !important;
      }
      & + h2 {
        margin-top: 20px !important;
      }
      & + h3 {
        margin-top: 20px !important;
      }
      & + strong {
        margin-top: 20px !important;
        display: inline-block;
      }
    }

    h1, h2, h3, h4, h5, h6, p {
      margin-bottom: 10px;
    }


    ul {
      margin-left: $baseline;
      margin-bottom: $baseline;
      padding-left: 0;
      position: relative;
      list-style: none !important;
      font-weight: $font-regular;

      li {
        position: relative;
        list-style: none !important;

        &:before {
          content: "\f111";
          font-family: "Font Awesome 5 Pro";
          font-weight: 700;
          font-size: 6px;
          color: $color-secondary;
          position: absolute;
          left: -20px;
          top: 10px;
        }
      }

      ul {
        margin-top: 0;
        margin-bottom: 0;
        list-style: none;

        li {
          position: relative;
          list-style: none !important;

          &:before {
            content: "\f111";
            font-family: "Font Awesome 5 Pro";
            font-weight: 300;
            font-size: 6px;
            color: $color-secondary;
            position: absolute;
            left: -20px;
            top: 10px;
          }
        }
      }
    }

    ol {
      margin-left: $baseline;
      margin-bottom: $baseline;
      padding-left: 0;
      position: relative;
      list-style: none !important;
      font-weight: $font-light;

      > li {
        position: relative;
        list-style: none !important;
        counter-increment: counter;

        &:before {
          content: counter(counter);
          font-weight: $font-light;
          font-size: 16px;
          color: $color-secondary;
          position: absolute;
          left: -20px;
          top: 2px;
        }
      }

      ol {
        margin-top: 0;
        margin-bottom: 0;

        li {
          position: relative;
          list-style: none !important;
          counter-increment: counter2;

          &:before {
            content: counter(counter2);
            font-weight: $font-light;
            font-size: 16px;
            color: $color-secondary;
            position: absolute;
            left: -20px;
            top: 2px;
          }
        }
      }
    }

    ul {
      margin-left: 25px;
      list-style: disc !important;

      ul {
        list-style: circle !important;
        margin-left: 15px;
        margin-bottom: 10px;
      }

      & + * {
        margin-top: 20px;
      }
    }


    hr {
      margin-top: 20px;
    }
  }
}

/**
 * 2) Element
 */

/**
 * 3) Modifier
 */
