/**
 * Helpers/debug stylesheet
 *
 * Table of contents
 * 1) Mixin
 * 2) Message
 */



/**
 * 1) Mixin
 */

@mixin debug-message {
    padding: $baseline/4 $baseline/2;

    background-color: rgba(0, 0, 0, .5);

    font-size: .75rem;
    text-transform: uppercase;
    color: #FFFFFF;

    position: fixed;
    right: 1em;
    bottom: 1em;
    z-index: $z-index-5;
}



/**
 * 2) Message
 */

body.debug {
    &:before {
        @include debug-message;
        content: "Breakpoint 0";
    }

    @include respond-min($xs) { &:before { content: "Breakpoint xs (1)"; } }
    @include respond-min($sm) { &:before { content: "Breakpoint sm (2)"; } }
    @include respond-min($md) { &:before { content: "Breakpoint md (3)"; } }
    @include respond-min($lg) { &:before { content: "Breakpoint lg (4)"; } }
}
